import { hasCookieBannerEnabled } from '@components/CookieBanner/shared/utils';

import { withData } from '@services/surveyDataProvider';

import PageFooter from './PageFooter';

const mapSurveyDataToProps = (surveyData) => ({
  cookieBannerEnabled: hasCookieBannerEnabled(surveyData)
});

export default withData(mapSurveyDataToProps)(PageFooter);
