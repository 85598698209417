import { connect } from 'react-redux';
import { compose } from 'redux';

import { hasCookieBannerEnabled } from '@components/CookieBanner/shared/utils';

import { withData } from '@services/surveyDataProvider';

import LanguageDropdown from './LanguageDropdown';

export const mapSurveyDataToProps = (surveyData) => ({
  enabled: hasCookieBannerEnabled(surveyData)
});

export const mapStateToProps = ({ cookieBanner }, { enabled }) => ({
  isCookieBannerOpened: enabled && cookieBanner.isOpen
});

const composedConnect = compose(withData(mapSurveyDataToProps), connect(mapStateToProps));

export default composedConnect(LanguageDropdown);
