import { isEmpty } from 'lodash';
import get from 'lodash/get';
import {
  CookieBanner,
  CookieBannerCategoryCookiesInfo,
  CookieBannerCookieInfoLabels,
  CookieBannerCookiesInfo,
  CookieConfiguration
} from 'types/SurveyData';

export const PANEL_ID_MAIN = 'V3_PANEL_MAIN';
export const PANEL_ID_LEGAL_NOTICE = 'V3_PANEL_LEGAL_NOTICE';
export const PANEL_ID_COOKIE_CONSENT = 'V3_PANEL_COOKIE_CONSENT';
export const PANEL_ID_COOKIE_SETTINGS = 'V3_PANEL_COOKIE_SETTINGS';

// # ----------------------------------------------------------------------
// # Categories
// # ----------------------------------------------------------------------

export const COOKIE_CATEGORY = {
  TECHNICALLY_NECESSARY: 'TECHNICALLY_NECESSARY',
  STATISTICS: 'STATISTICS',
  USER_EXPERIENCE: 'USER_EXPERIENCE'
};

export const PANEL_ID_CATEGORY = Object.values(COOKIE_CATEGORY)
  .map((category) => ({
    label: `V3_PANEL_COOKIE_CATEGORY_${category}`,
    key: category
  }))
  .reduce((acc, value) => ({ ...acc, [value.key]: value.label }), {});

export const isCookieCategoryPanel = (pathname: string) =>
  Object.values(PANEL_ID_CATEGORY).includes(pathname);

// # ----------------------------------------------------------------------
// # Normalizers
// # ----------------------------------------------------------------------

export const getCategories = (cookieBanner: CookieBanner) => {
  const technicallyNecessary = get(cookieBanner, 'technicallyNecessaryCookiesInfo', {});
  const optional = get(cookieBanner, 'optionalCategories', []);
  const deprecatedCookies = get(cookieBanner, 'deprecatedCookies', []);

  let filteredOptional = [];

  if (!isEmpty(deprecatedCookies)) {
    filteredOptional = optional.filter(
      (item) => filterDeprecatedCookies(item.cookiesInfo, deprecatedCookies).length != 0
    );
  } else {
    filteredOptional = optional;
  }

  return [
    {
      ...technicallyNecessary,
      categoryType: COOKIE_CATEGORY.TECHNICALLY_NECESSARY,
      consent: true
    },
    ...filteredOptional
  ];
};

const mapCookieInfo = (
  cookie: CookieBannerCookiesInfo,
  cookieInfoLabels: CookieBannerCookieInfoLabels
) => {
  const { name, duration, provider, purpose, type } = cookie;
  const { durationText, providerText, purposeText, typeText } = cookieInfoLabels;

  return {
    id: name,
    properties: {
      [durationText]: duration,
      [providerText]: provider,
      [purposeText]: purpose,
      [typeText]: type
    }
  };
};

export const getCategoriesDetails = (
  cookieBanner: CookieBanner,
  category: keyof typeof COOKIE_CATEGORY,
  cookieInfoLabels: CookieBannerCookieInfoLabels
) => {
  const { technicallyNecessaryCookiesInfo, optionalCategories } = cookieBanner;
  let technicallyNecessaryCookies: CookieBannerCategoryCookiesInfo['cookiesInfo'] = [];
  const deprecatedCookies = get(cookieBanner, 'deprecatedCookies', []);

  if (!isEmpty(deprecatedCookies)) {
    technicallyNecessaryCookies = filterDeprecatedCookies(
      technicallyNecessaryCookiesInfo.cookiesInfo,
      deprecatedCookies
    );
  } else {
    technicallyNecessaryCookies = technicallyNecessaryCookiesInfo.cookiesInfo;
  }

  if (category === COOKIE_CATEGORY.TECHNICALLY_NECESSARY) {
    return {
      title: technicallyNecessaryCookiesInfo.title,
      description: technicallyNecessaryCookiesInfo.message,
      cookies: technicallyNecessaryCookies.map((cookie) => mapCookieInfo(cookie, cookieInfoLabels))
    };
  }

  const categoryInfo = optionalCategories.find((item) => item.categoryType === category)!;
  let optionalCookies = [];

  if (!isEmpty(deprecatedCookies)) {
    optionalCookies = filterDeprecatedCookies(categoryInfo.cookiesInfo, deprecatedCookies);
  } else {
    optionalCookies = categoryInfo.cookiesInfo;
  }

  return {
    title: categoryInfo.title,
    description: categoryInfo.message,
    cookies: optionalCookies.map((cookie) => mapCookieInfo(cookie, cookieInfoLabels))
  };
};

const filterDeprecatedCookies = (
  cookiesInfo: Array<CookieBannerCookiesInfo>,
  deprecatedCookies: CookieConfiguration['deprecatedCookies']
) => {
  const filteredDeprecatedOptionalCookies = cookiesInfo.filter(
    (item) => !deprecatedCookies.includes(item.name)
  );

  return filteredDeprecatedOptionalCookies;
};
