import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { SurveyData } from 'types/SurveyData';

import { RootState } from '@redux/store';

import DebuggerService, {
  BrandingStyleSheets,
  FeatureFlagRegistry,
  SurveySpec
} from '@services/debugger';
import {
  ClientSideRenderingFeatureFlag,
  normalizeFeatureFlagsList,
  normalizeSurveyEngineFeatureFlagsList
} from '@services/featureFlags';

import { isLocalServer } from '@utils/meta';

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

export type SurveyDebuggerState = typeof initialState;

const initialState = {
  isRuntimeDebuggerEnabled: false,
  isSpecDebuggerEnabled: false,
  surveySpec: {} as SurveySpec,
  clientFeatureFlags: {
    prev: {} as FeatureFlagRegistry,
    draft: {} as FeatureFlagRegistry,
    final: {} as FeatureFlagRegistry
  },
  brandingStyleSheets: [] as BrandingStyleSheets,
  loading: {
    surveySpec: false,
    brandingStyleSheets: false,
    initDebugger: false
  },
  error: {
    surveySpec: null as string | null,
    brandingStyleSheets: null as string | null,
    initDebugger: null as string | null
  }
};

//----------------------------------------------------------------------
// THUNKS
//----------------------------------------------------------------------

export const fetchSurveySpec = createAsyncThunk(
  'debugger/fetchSurveySpec',
  async (_, { rejectWithValue }) => {
    try {
      return await DebuggerService.fetchSurveySpec();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchBrandingStyleSheets = createAsyncThunk(
  'debugger/fetchBrandingStyleSheets',
  async (_, { rejectWithValue }) => {
    try {
      return await DebuggerService.fetchBrandingStyleSheets();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const initDebugger = createAsyncThunk(
  'debugger/init',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      if (!DebuggerService.hasDebuggerParam()) {
        return false;
      }

      dispatch(initClientFeatureFlags());
      await dispatch(fetchBrandingStyleSheets());

      if (isLocalServer()) {
        return true;
      }

      const vpnProtected = await DebuggerService.fetchVpnStatus();
      if (!vpnProtected) {
        return false;
      }

      dispatch(fetchSurveySpec());
      return true;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const setOverride = createAsyncThunk(
  'debugger/setOverride',
  async (payload: Partial<SurveyData>) => {
    DebuggerService.setOverride(payload);
  }
);

export const clearAllOverrides = createAsyncThunk('debugger/clearAllOverrides', async () => {
  DebuggerService.clearAllOverrides();
});

//----------------------------------------------------------------------
// SLICE
//----------------------------------------------------------------------

const surveyDebuggerSlice = createSlice({
  name: 'debugger',
  initialState,
  reducers: {
    initClientFeatureFlags: (state) => {
      const surveyData = normalizeFeatureFlagsList(window.surveyData.featureFlags);
      const surveyDataOverrides = DebuggerService.getOverrides().featureFlags || {};

      state.clientFeatureFlags.draft = surveyDataOverrides as FeatureFlagRegistry;
      state.clientFeatureFlags.final = surveyData;
      state.clientFeatureFlags.prev = surveyData;
    },
    updateClientFeatureFlag: (
      state,
      action: PayloadAction<{ id: ClientSideRenderingFeatureFlag; value: boolean }>
    ) => {
      const { id, value } = action.payload;
      state.clientFeatureFlags.draft[id] = value;
      state.clientFeatureFlags.final[id] = value;
    },
    resetClientFeatureFlags: (state) => {
      state.clientFeatureFlags.draft = {} as FeatureFlagRegistry;
      state.clientFeatureFlags.final = state.clientFeatureFlags.prev;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetchBrandingStyleSheets
      .addCase(fetchBrandingStyleSheets.pending, (state) => {
        state.loading.brandingStyleSheets = true;
      })
      .addCase(fetchBrandingStyleSheets.fulfilled, (state, action) => {
        state.loading.brandingStyleSheets = false;
        state.brandingStyleSheets = action.payload;
      })
      .addCase(fetchBrandingStyleSheets.rejected, (state, action) => {
        state.loading.brandingStyleSheets = false;
        state.error.brandingStyleSheets = action.payload as string;
      })
      // fetchSurveySpec
      .addCase(fetchSurveySpec.pending, (state) => {
        state.loading.surveySpec = true;
      })
      .addCase(fetchSurveySpec.fulfilled, (state, action) => {
        state.loading.surveySpec = false;
        if (action.payload) {
          const { content, size } = action.payload;
          state.surveySpec = DebuggerService.parseSurveySpec(content, size);
          state.isSpecDebuggerEnabled = true;
        }
      })
      .addCase(fetchSurveySpec.rejected, (state, action) => {
        state.loading.surveySpec = false;
        state.error.surveySpec = action.payload as string;
      })
      // initDebugger
      .addCase(initDebugger.pending, (state) => {
        state.loading.initDebugger = true;
      })
      .addCase(initDebugger.fulfilled, (state, action) => {
        state.loading.initDebugger = false;
        state.isRuntimeDebuggerEnabled = action.payload;
      })
      .addCase(initDebugger.rejected, (state, action) => {
        state.loading.initDebugger = false;
        state.error.initDebugger = action.payload as string;
      });
  }
});

//----------------------------------------------------------------------
// ACTIONS
//----------------------------------------------------------------------

export const { updateClientFeatureFlag, resetClientFeatureFlags, initClientFeatureFlags } =
  surveyDebuggerSlice.actions;

//----------------------------------------------------------------------
// REDUCER
//----------------------------------------------------------------------

export default surveyDebuggerSlice.reducer;

//----------------------------------------------------------------------
// SELECTORS
//----------------------------------------------------------------------

export const surveyDebuggerSelector = (state: RootState) => state.surveyDebugger;
