import { deepFreeze } from '@services/surveyDataProvider/helpers';

let store;

/**
 * This store contains the application static data
 * Updating it's content won't refresh the component.
 * To store dynamic data that represents application state
 * use Redux store
 */

const setStore = (newStore) => {
  store = newStore;
  if (process.env.NODE_ENV !== 'production') {
    window.surveyDataStore = store;
  }
};

setStore(null);

export const getStore = () => {
  if (!store) {
    const error = new Error('You should create the provider with a valid store first');
    throw error;
  }
  return store;
};

export const createProvider = (surveyBlocksData, cookieNotice = {}, cookieConfiguration = {}) => {
  const surveyBlocks = surveyBlocksData.reduce((carry, item) => {
    const newCarry = carry;
    newCarry[item.id] = item;
    return newCarry;
  }, {});
  setStore(
    deepFreeze({
      blocks: surveyBlocks,
      cookieBanner: { ...cookieNotice, ...cookieConfiguration }
    })
  );
  return getStore();
};

export default createProvider;
