import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';
import Question from '@commons/Question';

import layoutStyles from '@css/layout.scss';

import {
  isTextAreaAccessibilityEnabled,
  isTextfieldWithoutFieldsetEnabled,
  isWCAG21Enabled
} from '@services/featureFlags';
import { getTranslation } from '@services/translations';

import { isEnterKey } from '@utils/detectKey';
import stripHtml from '@utils/stripHtml';

import styles from './textField.scss';

const DEFAULT_TEXTAREA_CHARACTER_LIMIT_REACHED = 'Character limit reached.';

interface TextFieldProps {
  formkey: string;
  caption: string;
  maxChars: number;
  validationEmpty: string;
  validationFailed: string;
  requiredField: string;
  setComponentAnswer: Function;
  isSubComponent: boolean;
  validationMessages: Array<string>;
  value: string;
  id: number;
}

interface TextFieldState {
  currentCount: number;
  textfieldIsFocused: boolean | undefined;
  value: TextFieldProps['value'];
  ariaBusyOnChange: Record<string, string> | null;
  ariaLiveRegionRelationship: string;
}

export default class TextField extends Component<TextFieldProps, TextFieldState> {
  static propTypes = {
    formkey: PropTypes.string,
    caption: PropTypes.string,
    maxChars: PropTypes.number,
    validationEmpty: PropTypes.string,
    validationFailed: PropTypes.string,
    requiredField: PropTypes.string,
    validationMessages: PropTypes.arrayOf(PropTypes.string),
    setComponentAnswer: PropTypes.func,
    isSubComponent: PropTypes.bool
  };

  static defaultProps = {
    formkey: '',
    caption: '',
    validationEmpty: null,
    validationFailed: null,
    validationMessages: [],
    requiredField: null,
    isSubComponent: false
  };

  debouncedSetAnswer: typeof this.setAnswer;

  constructor(props: TextFieldProps) {
    super(props);

    const currentCount = (props.value && props.value.length) || 0;
    this.state = {
      currentCount,
      value: props.value || '',
      ariaLiveRegionRelationship: 'aria-describedby',
      ariaBusyOnChange: null,
      textfieldIsFocused: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateCharacterCount = this.updateCharacterCount.bind(this);
    this.debouncedSetAnswer = debounce(this.setAnswer.bind(this), 300);
  }

  setAnswer(value: string) {
    this.props.setComponentAnswer(value);
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    const currentText = e.target.value;
    // recalculate the number of characters typed in so far
    this.updateCharacterCount(currentText);
    const characterCount = currentText.length;
    const { maxChars } = this.props;
    let ariaBusyOnChange = null;

    if (maxChars) {
      const assertiveFlag = maxChars * 0.9;

      if (characterCount >= assertiveFlag) {
        ariaBusyOnChange = { 'aria-busy': 'false' };
      } else {
        if (characterCount % 50 === 0) {
          ariaBusyOnChange = { 'aria-busy': 'false' };
        } else {
          ariaBusyOnChange = { 'aria-busy': 'true' };
        }
      }
    }
    this.setState({
      value: currentText,
      ariaBusyOnChange,
      ariaLiveRegionRelationship: 'aria-controls'
    });
    this.debouncedSetAnswer(currentText);
  }

  handleOnTextfieldBlur = () => {
    this.setState({ textfieldIsFocused: false, ariaLiveRegionRelationship: 'aria-describedby' });
  };

  handleOnTextfieldFocus = () => {
    this.setState({ textfieldIsFocused: true });
  };

  updateCharacterCount(newText: string) {
    const currentCount = (newText && newText.length) || 0;
    this.setState({ currentCount });
  }

  getAriaDescribedBy(charCountId: string, charLimitId: string) {
    const { maxChars } = this.props;
    const ariaDescriptors = [];

    if (maxChars) {
      ariaDescriptors.push(charCountId);
    }

    if (isTextAreaAccessibilityEnabled()) {
      maxChars && this.state.currentCount == maxChars;
      ariaDescriptors.push(charLimitId);
    }

    if (!ariaDescriptors.length) {
      return {};
    }

    const ariaDescribedByText = ariaDescriptors.join(' ');
    return { [this.state.ariaLiveRegionRelationship]: ariaDescribedByText };
  }

  onKeyPress(e: KeyboardEvent<HTMLInputElement>) {
    return isEnterKey(e) && e.preventDefault();
  }

  render() {
    const {
      id,
      formkey,
      caption,
      maxChars,
      validationEmpty,
      validationFailed,
      requiredField,
      validationMessages,
      isSubComponent
    } = this.props;

    const ariaRequired = (requiredField || validationEmpty) && { 'aria-required': true };
    const ariaInvalid = (validationFailed || validationEmpty || validationMessages.length) && {
      'aria-invalid': true
    };

    const maxLength = maxChars ? { maxLength: maxChars } : {};
    const { ariaBusyOnChange, textfieldIsFocused } = this.state;
    const textFieldContainerStyles = classNames(styles.textfieldContainer, layoutStyles.answer, {
      // @ts-ignore
      [styles.textfieldContainer_isFocused]: textfieldIsFocused
    });
    const textfieldMessageContainerStyles = classNames(styles.textfieldMessageContainer);
    // @ts-ignore
    const iconClassName = styles['icon_lineHeight'];

    let ariaLive, ariaAtomic, ariaBusy;

    ariaLive = { 'aria-live': 'assertive' } as const;
    ariaAtomic = { 'aria-atomic': true };

    if (!ariaBusyOnChange) {
      ariaBusy = { 'aria-busy': 'true' };
    } else {
      ariaBusy = ariaBusyOnChange;
    }

    const charCountId = `${formkey}_CharCount`;
    const charLimitId = `${formkey}_CharLimit`;

    const ariaLabelledBy = id >= 0 && !isWCAG21Enabled() ? { 'aria-labelledby': `${id}` } : {};
    const ariaDescribedBy = this.getAriaDescribedBy(charCountId, charLimitId);
    const ariaLabel = { 'aria-label': `${stripHtml(caption)}` };

    const characterLimitText =
      getTranslation('survey.TEXTAREA_CHARACTER_LIMIT_REACHED') ||
      DEFAULT_TEXTAREA_CHARACTER_LIMIT_REACHED;

    const component = [
      !isSubComponent && (
        <Question
          key="question"
          ariaId={formkey}
          hasLegendCaption={!isTextfieldWithoutFieldsetEnabled()}
          caption={caption}
          captionId={String(id)}
          validationEmpty={validationEmpty}
          validationFailed={validationFailed}
          requiredField={requiredField}
          validationMessages={validationMessages}
        />
      ),
      <div key="input" className={classNames({ [layoutStyles.answers]: !isSubComponent })}>
        <div className={textFieldContainerStyles}>
          <input
            id={formkey}
            name={formkey}
            type="text"
            title={stripHtml(caption)}
            className={styles.textField}
            onChange={this.handleChange}
            value={this.state.value}
            onKeyPress={this.onKeyPress}
            onFocus={this.handleOnTextfieldFocus}
            onBlur={this.handleOnTextfieldBlur}
            {...maxLength}
            {...ariaRequired}
            {...ariaInvalid}
            {...ariaLabelledBy}
            {...ariaDescribedBy}
            {...ariaLabel}
          />
        </div>

        {isTextAreaAccessibilityEnabled() ? (
          <div className={textfieldMessageContainerStyles}>
            {maxChars && this.state.currentCount >= maxChars && (
              <div className={styles.textfieldWarningBlock}>
                <Icon containerStyles={iconClassName} type={ICON_TYPES.WARNING} />
                <span id={charLimitId} role="alert" className={styles.textfieldCharacterLimit}>
                  {characterLimitText}
                </span>
              </div>
            )}

            {maxChars && (
              <div id={charCountId} className={styles.textfieldCount}>
                {`${this.state.currentCount} / ${maxChars}`}
                <span className={styles.textfieldCharacterTyped}> Characters Typed</span>
              </div>
            )}
          </div>
        ) : (
          <div className={textfieldMessageContainerStyles}>
            {maxChars && (
              <div
                id={charCountId}
                className={styles.textfieldCount}
                {...ariaLive}
                {...ariaAtomic}
                {...ariaBusy}
              >
                {`${this.state.currentCount} / ${maxChars}`}
                <span className={styles.textfieldCharacterTyped}> Characters Typed</span>
              </div>
            )}
          </div>
        )}
      </div>
    ];

    if (isTextfieldWithoutFieldsetEnabled()) {
      return <div className="questionBlock textfieldQuestion">{component}</div>;
    }

    return (
      <div className="questionBlock textfieldQuestion">
        <fieldset>{component}</fieldset>
      </div>
    );
  }
}
