import { getCategories } from '@components/CookieBanner/V3/utils';

import { ACCEPT_COOKIES, SET_COOKIE_CATEGORIES, TOGGLE_BANNER } from '@redux/actions/cookieBanner';

import { getCookieBannerSessionStatus } from '@services/cookieBannerSessionData';

export const getInitialState = (bannerSurveyData = {}) => {
  const sessionData = getCookieBannerSessionStatus(bannerSurveyData.cookieConsentId);
  const consentDate = sessionData.consentDate || bannerSurveyData.cookieConsentDate;
  let consentCategories = bannerSurveyData.cookieConsentCategories || [];
  if (!consentCategories.includes('TECHNICALLY_NECESSARY')) {
    consentCategories = [...consentCategories, 'TECHNICALLY_NECESSARY'];
  }
  const allCategories = getCategories(bannerSurveyData).map((item) => item.categoryType) || [];

  return {
    consentDate,
    consentCategories,
    isOpen: sessionData.isOpen !== undefined ? sessionData.isOpen : !consentDate,
    allCategories
  };
};

export default function reduce(state = {}, action) {
  if (action.type === ACCEPT_COOKIES) {
    return {
      ...state,
      consentDate: action.payload.consentDate,
      isOpen: false,
      opener: null
    };
  }

  if (action.type === SET_COOKIE_CATEGORIES) {
    const updatedCategories = new Set([
      'TECHNICALLY_NECESSARY',
      ...action.payload.consentCategories
    ]);
    return {
      ...state,
      consentCategories: Array.from(updatedCategories),
      allCategories: action.payload.allCategories
    };
  }

  if (action.type === TOGGLE_BANNER) {
    return {
      ...state,
      isOpen: !state.isOpen,
      opener: !state.isOpen ? action.payload.opener : null
    };
  }
  return state;
}
