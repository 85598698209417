import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { acceptCookies } from '@redux/actions/cookieBanner';

import { withData } from '@services/surveyDataProvider';

import PanelMain from './PanelMain';

export const mapSurveyDataToProps = (surveyData) => {
  const { cookieBanner } = surveyData;

  return {
    acceptButtonText: get(cookieBanner, 'acceptButtonText', ''),
    title: get(cookieBanner, 'title', ''),
    description: get(cookieBanner, 'introductionMessage', ''),
    legalInfoLinkText: get(cookieBanner, 'legalInfo.title', ''),
    technicallyNecessaryLinkText: get(cookieBanner, 'technicallyNecessaryCookiesInfo.title', '')
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onAccept: () => dispatch(acceptCookies())
});

const composedConnect = compose(withData(mapSurveyDataToProps), connect(null, mapDispatchToProps));

export default composedConnect(PanelMain);
