import classNames from 'classnames';
import debounce from 'lodash/debounce';
import findLastIndex from 'lodash/findLastIndex';
import React, { Component } from 'react';

import ProgressBarSteps from '@commons/ProgressBarSteps';

import { isTextAreaAccessibilityEnabled } from '@services/featureFlags';

import styles from './qualityMeter.scss';

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

export interface QualityMeterText {
  count: number;
  text: string;
  barText: string;
}

interface Props {
  id: string;
  characterCount: number;
  showQualityMeter: boolean;
  qualityMeterTexts: Array<QualityMeterText>;
}

interface State {
  ariaLabel: string;
}

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

class QualityMeter extends Component<Props, State> {
  static defaultProps = {
    id: ''
  };

  debouncedUpdateAriaLabel: typeof this.updateAriaLabel;

  constructor(props: Props) {
    super(props);
    const ariaLabel = this.getAriaLabel(props);
    this.state = { ariaLabel };

    this.debouncedUpdateAriaLabel = debounce(this.updateAriaLabel, 1000);
  }

  getActiveTextIndex(props = this.props) {
    const { characterCount, qualityMeterTexts } = props;
    return findLastIndex(qualityMeterTexts, (item, index) => {
      if (index === 0) {
        return item.count <= characterCount;
      }
      return item.count < characterCount;
    });
  }

  getActiveItem(props = this.props) {
    const activeTextIndex = this.getActiveTextIndex(props);
    return activeTextIndex > -1 ? this.props.qualityMeterTexts[activeTextIndex] : null;
  }

  getCurrentLabel(props = this.props) {
    const activeItem = this.getActiveItem(props);
    return activeItem ? activeItem.text : '';
  }

  getAriaLabel(props = this.props) {
    const activeItem = this.getActiveItem(props);
    if (isTextAreaAccessibilityEnabled()) {
      return activeItem ? [activeItem.barText].join('. ') : '';
    } else {
      return activeItem ? [activeItem.text, activeItem.barText].join('. ') : '';
    }
  }

  updateAriaLabel(props: Props) {
    const ariaLabel = this.getAriaLabel(props);
    this.setState({ ariaLabel });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.characterCount !== nextProps.characterCount) {
      this.debouncedUpdateAriaLabel(nextProps);
    }
  }

  render() {
    const { qualityMeterTexts, showQualityMeter, id } = this.props;
    const numberOfSteps = qualityMeterTexts.length - 1;

    const activeTextIndex = this.getActiveTextIndex();
    const activeItem = this.getActiveItem();
    const currentLabel = this.getCurrentLabel();
    const isQualityMeterActive = showQualityMeter && activeItem;

    const qualityMeterContainerStyles = classNames(styles.qualityMeterContainer, {
      // @ts-ignore
      [styles.qualityMeterContainer_isActive]: isQualityMeterActive
    });

    const sharedContainerProps = {
      className: qualityMeterContainerStyles,
      ['aria-hidden']: !isQualityMeterActive,
      ['aria-live']: 'polite',
      id
    } as const;

    return (
      <>
        {isTextAreaAccessibilityEnabled() ? (
          <div {...sharedContainerProps}>
            <div className={styles.qualityMeterLabel}>{currentLabel}</div>
            <div className={styles.qualityMeterLabelVisuallyHidden}>{this.state.ariaLabel}</div>
            <div className={styles.qualityMeterProgressBar} aria-hidden>
              <ProgressBarSteps steps={numberOfSteps} currentActive={activeTextIndex} />
            </div>
          </div>
        ) : (
          <div {...sharedContainerProps} aria-label={this.state.ariaLabel}>
            <div className={styles.qualityMeterLabel} aria-hidden>
              {currentLabel}
            </div>
            <div className={styles.qualityMeterProgressBar} aria-hidden>
              <ProgressBarSteps steps={numberOfSteps} currentActive={activeTextIndex} />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default QualityMeter;
