import { get } from 'lodash';

export const isSafariMobile = () => {
  const userAgent = get(window, ['navigator', 'userAgent'], null);

  if (!userAgent) {
    return;
  }

  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const webkit = !!userAgent.match(/WebKit/i);
  return iOS && webkit && !userAgent.match(/CriOS/i);
};

export const getIOSVersion = () => {
  const userAgent = get(window, ['navigator', 'userAgent'], null);

  if (!userAgent) {
    return null;
  }

  const iOSVersion = userAgent.match(/OS (\d+)_(\d+)/) || [];

  if (iOSVersion) {
    const iOSMajor = iOSVersion[1];
    const iOSMinor = iOSVersion[2];
    return { iOSMajor, iOSMinor };
  }

  return null;
};
