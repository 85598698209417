import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button, { BUTTON_STYLES } from '@commons/Button';
import Modal from '@commons/Modal';

import styles from './timeoutWarning.scss';

export default class TimeoutWarning extends Component {
  static propTypes = {
    surveyTimeoutSeconds: PropTypes.number,
    surveyTimeoutWarningSeconds: PropTypes.number,
    shouldRender: PropTypes.bool
  };

  static defaultProps = {
    shouldRender: false
  };

  static contextTypes = {
    translations: PropTypes.object
  };

  constructor(props, context) {
    super(props);

    this.timeRemaining = props.surveyTimeoutSeconds - props.surveyTimeoutWarningSeconds;
    this.state = {
      timeoutWarningVisible: false,
      timeRemainingMessage: this.getTimeOutMessage(this.timeRemaining, context.translations)
    };

    this.showTimeOutPopOverAfterSurveyTimeoutWarningSeconds =
      this.showTimeOutPopOverAfterSurveyTimeoutWarningSeconds.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.decrementTimeRemaining = this.decrementTimeRemaining.bind(this);
    this.getTimeOutMessage = this.getTimeOutMessage.bind(this);
  }

  componentWillMount() {
    this.showTimeOutPopOverAfterSurveyTimeoutWarningSeconds(this);
  }

  handleShow() {
    this.setState({ timeoutWarningVisible: true });

    //Start the loop
    setTimeout(() => {
      this.decrementTimeRemaining();
    }, 60 * 1000);
  }

  handleHide() {
    this.setState({ timeoutWarningVisible: false });
    this.timeRemaining = this.props.surveyTimeoutSeconds - this.props.surveyTimeoutWarningSeconds;
    this.showTimeOutPopOverAfterSurveyTimeoutWarningSeconds(this);

    const form = document.getElementsByTagName('form')[0];

    form.elements['defPgrAction'].value = '';
    form.submit();
  }

  showTimeOutPopOverAfterSurveyTimeoutWarningSeconds(that) {
    return setTimeout(() => {
      if (this.timeRemaining > 0) {
        that.handleShow();
      }
    }, that.props.surveyTimeoutWarningSeconds * 1000);
  }

  decrementTimeRemaining() {
    this.timeRemaining = Math.round(this.timeRemaining - 60);
    this.setState({
      timeRemainingMessage: this.getTimeOutMessage(this.timeRemaining, this.context.translations)
    });

    return setTimeout(() => {
      if (this.timeRemaining >= 60) {
        this.decrementTimeRemaining();
      } else if (this.timeRemaining <= 0) {
        window.location = window.location.href.split('#')[0];
      } else {
        return;
      }
    }, 60 * 1000);
  }

  getTimeOutMessage(timeRemaining, translations) {
    if (timeRemaining > 60) {
      return translations.TIMEOUT_WARNING_CONTENT_TIME_N_MINUTES.replace('%d', timeRemaining / 60);
    } else {
      return translations.TIMEOUT_WARNING_CONTENT_TIME_LESS_THAN_ONE_MINUTE;
    }
  }

  render() {
    if (!this.props.shouldRender) return null;

    const { surveyTimeoutSeconds, surveyTimeoutWarningSeconds } = this.props;
    const translations = this.context.translations;

    const timeOutWarningContainer = classNames(styles.timeoutWarningContainer, 'timeoutWarning');

    const timeoutWarningBody = classNames(styles.timeoutWarningBody);

    const button = {
      domId: 'timeout-warning-confirm',
      caption: translations.TIMEOUT_WARNING_ACKNOWLEDGE,
      formKey: 'timeout-warning-confirm',
      name: 'timeout-warning-confirm'
    };

    return (
      <div>
        {this.state.timeoutWarningVisible && (
          <Modal
            onClickHide={this.handleHide}
            modalContainerStyles={timeOutWarningContainer}
            label={translations.TIMEOUT_WARNING_HEADER}
            cancelButtonLabel={translations.TA_CLOSE}
          >
            <div className={'timeoutBody'}>
              <div className={styles.timeoutWarningBodyMessage}>
                {translations.TIMEOUT_WARNING_CONTENT_BEFORE_TIME}
                {this.state.timeRemainingMessage}
                {translations.TIMEOUT_WARNING_CONTENT_AFTER_TIME}
              </div>
              <Button
                {...button}
                styling={BUTTON_STYLES.TERTIARY}
                onClickCallBack={this.handleHide}
                showArrowIcon
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
