import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  isGeolocationFetchingSpinnerEnabled,
  isGeolocationTimeoutEnabled
} from '@services/featureFlags';

import styles from './geolocation.scss';

const MOVE_FORWARD_BUTTON_SELECTOR = '._moveForwardButton_';
export const GEOLOCATION_MOVE_FORWARD_BUTTON_CLICKED = 'GEOLOCATION_MOVE_FORWARD_BUTTON_CLICKED';

export default class GeoLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      longitude: '',
      latitude: ''
    };

    this.handleCurrentPosition = this.handleCurrentPosition.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleButtonOnClick = this.handleButtonOnClick.bind(this);
    this.getCurrPosition = this.getCurrPosition.bind(this);
  }

  componentDidMount() {
    const moveForwardButton = document.querySelector(MOVE_FORWARD_BUTTON_SELECTOR);
    if (!moveForwardButton) {
      return;
    }
    moveForwardButton.addEventListener('click', this.getCurrPosition);
  }

  componentWillUnmount() {
    const moveForwardButton = document.querySelector(MOVE_FORWARD_BUTTON_SELECTOR);
    if (!moveForwardButton) {
      return;
    }
    moveForwardButton.removeEventListener('click', this.getCurrPosition);
  }

  getCurrPosition(e) {
    // TODO: Understand if any telemetry applies here.
    // mark(GEOLOCATION_MOVE_FORWARD_BUTTON_CLICKED);
    if (isGeolocationFetchingSpinnerEnabled()) {
      this.props.setPageFetchingStatus();
    }
    e.preventDefault();
    if (window.navigator.geolocation) {
      const geoLocation = window.navigator.geolocation;
      if (isGeolocationTimeoutEnabled()) {
        const fetchOptions = {
          timeout: this.props.fetchTimeout
        };
        return geoLocation.getCurrentPosition(
          this.handleCurrentPosition,
          this.handleError,
          fetchOptions
        );
      }
      return geoLocation.getCurrentPosition(this.handleCurrentPosition, this.handleError);
    }
  }

  handleButtonOnClick() {
    document.querySelector('form').submit();
    if (isGeolocationFetchingSpinnerEnabled()) {
      this.props.setPageReadyStatus();
    }
  }

  handleCurrentPosition(position) {
    if (position && position.coords) {
      const longitude = Math.floor(position.coords.longitude * 1000000);
      const latitude = Math.floor(position.coords.latitude * 1000000);
      this.setState({ longitude, latitude }, () => {
        this.handleButtonOnClick();
      });
    } else {
      this.handleButtonOnClick();
    }
  }

  handleError() {
    this.handleButtonOnClick();
  }

  render() {
    return (
      <div className="geoLocationContainer">
        <input type="hidden" id="latitude" name="latitude" value={this.state.latitude} />
        <input type="hidden" id="longitude" name="longitude" value={this.state.longitude} />
      </div>
    );
  }
}

GeoLocation.propTypes = {
  fetchTimeout: PropTypes.number,
  setPageFetchingStatus: PropTypes.func.isRequired,
  setPageReadyStatus: PropTypes.func.isRequired
};

GeoLocation.defaultProps = {
  fetchTimeout: 10000
};
