import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCategories } from '@components/CookieBanner/V3/utils';

import {
  acceptCookies,
  allowAllCookieCategories,
  revokeAllCookieCategories,
  toggleCookieCategory
} from '@redux/actions/cookieBanner';

import { withData } from '@services/surveyDataProvider';

import PanelCookieSettings from './PanelCookieSettings';

export const mapSurveyDataToProps = (surveyData) => {
  const { cookieBanner } = surveyData;
  return {
    categories: getCategories(cookieBanner),
    // Translations
    title: get(cookieBanner, 'cookieNoticeSettings.manageTitle', ''),
    confirmText: get(cookieBanner, 'cookieNoticeSettings.confirm', ''),
    cookieDetailsText: get(cookieBanner, 'cookieNoticeSettings.cookieDetails', ''),
    selectAllCookiesText: get(cookieBanner, 'cookieNoticeSettings.selectAllCookies', ''),
    requiredText: get(cookieBanner, 'cookieNoticeSettings.required', '')
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onSelectAll: () => dispatch(allowAllCookieCategories()),
  onDeselectAll: () => dispatch(revokeAllCookieCategories()),
  onToggleCategory: (category) => dispatch(toggleCookieCategory(category)),
  onConfirmChoices: () => {
    dispatch(acceptCookies());
  }
});

export const mapStateToProps = (store, surveyData) => {
  const selectedCategories = store.cookieBanner.consentCategories;

  const categories = surveyData.categories.map((category) => {
    const consent = selectedCategories.includes(category.categoryType);
    return { ...category, consent };
  });

  const allSelected = selectedCategories.length === categories.length;
  return {
    categories,
    allSelected
  };
};

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composedConnect(PanelCookieSettings);
