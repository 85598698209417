import get from 'lodash/get';

import { RootState } from '@redux/store';

import { withData } from '@services/surveyDataProvider';

import PanelLegalNotice from './PanelLegalNotice';

export const mapSurveyDataToProps = (state: RootState) => {
  const { cookieBanner } = state;
  return {
    title: get(cookieBanner, 'legalInfo.title', ''),
    description: get(cookieBanner, 'legalInfo.message', '')
  };
};

export default withData(mapSurveyDataToProps)(PanelLegalNotice);
