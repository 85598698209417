import get from 'lodash/get';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition/';

import answerQuestion from '@redux/actions/answerQuestion';
import setComponentData from '@redux/actions/setComponentData';
import { RootState } from '@redux/store';

import ValidationsServiceInstance from '@services/validations';

import MultimediaFeedback from './MultimediaFeedback';

export function mapStateToProps(state: RootState, { id }) {
  const questions = state.questions || {};
  const question = questions[id];
  if (!question) return {};

  const htmlInputs = state.htmlInputs || {};

  const htmlInputsWithValue = [...question.htmlInputs].reduce((obj, htmlInput) => {
    if (htmlInputs[htmlInput]) {
      obj[htmlInput] = htmlInputs[htmlInput].value; // eslint-disable-line no-param-reassign
    }

    return obj;
  }, {});

  // # ----------------------------------------------------------------------
  // MPC-5193 LivingLens Upload Validation
  // # ----------------------------------------------------------------------

  const validationID = 0;
  // ↑ TODO: Rethink this once we have multiple validations for this component.

  const validations = ValidationsServiceInstance.components.get(id);
  const pass = ValidationsServiceInstance.componentConformsToValidation(id, validationID);
  const retries = get(state.components, [id, 'validations', [validationID], 'retries'], 0);
  const warning = validations[validationID].message;

  return {
    ...question,
    htmlInputs: htmlInputsWithValue,
    uploadValidationPass: pass,
    uploadValidationRetries: retries,
    uploadValidationWarning: warning
  };
}

const mapDispatchToProps = {
  answerQuestion,
  setComponentData
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default compose(withCondition, connector)(MultimediaFeedback);

type PropsFromRedux = ConnectedProps<typeof connector>;
