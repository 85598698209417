import { domains, signalCategories, signalTypes } from '@m/rum-client';
import { Event } from '@m/rum-client/esm/signals/events/event';

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

type Handler = (evt: Signal) => void;

interface JQueryCall {
  method: string;
  parameters: string;
  originalParameters: any[];
}

class Signal extends Event {
  public signalName: string = 'event_jquery_function_call';
  public domain: typeof domains.SESSION = domains.SESSION;

  constructor(attributes: Pick<JQueryCall, 'method' | 'parameters'>) {
    super({ label: 'jQuery function call', domain: domains.SESSION, attributes });

    this.setSignalType(signalTypes.EVENT);
    this.setCategory(signalCategories.SESSION);
  }
}

//----------------------------------------------------------------------
// CORE
//----------------------------------------------------------------------

export class JQueryDetector {
  static #instance: JQueryDetector;
  private originalJQuery: JQueryStatic;
  private registry: JQueryCall[];

  public static get instance(): JQueryDetector {
    if (!JQueryDetector.#instance) {
      JQueryDetector.#instance = new JQueryDetector();
    }
    return JQueryDetector.#instance;
  }

  private constructor() {
    this.originalJQuery = (window.$ || window.jQuery) as JQueryStatic;
    this.registry = [];
  }

  //----------------------------------------------------------------------
  // UTILS
  //----------------------------------------------------------------------

  private registerCall(call: JQueryCall) {
    this.registry.push(call);
  }

  private wrapMethod(methodName: string, method: Function): Function {
    const self = this;

    return function (this: any, ...args: any[]): any {
      const call: JQueryCall = {
        method: methodName,
        originalParameters: args,
        parameters: args.toString()
      };

      self.registerCall(call);
      return method.apply(this, args);
    };
  }

  private wrapFunction(result: JQuery<HTMLElement>): JQuery<HTMLElement> {
    const self = this;

    if (typeof result === 'object' && result !== null) {
      return new Proxy(result, {
        get(target: JQuery<HTMLElement>, prop: string | symbol): any {
          const value = target[prop as keyof JQuery<HTMLElement>];
          if (typeof value === 'function') {
            return self.wrapMethod(String(prop), value.bind(target));
          }
          return value;
        }
      });
    }

    return result;
  }

  //----------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------

  public init() {
    const self = this;

    const interceptor = function (this: any, ...args: any[]): JQuery<HTMLElement> {
      const call: JQueryCall = {
        method: 'jQuery',
        originalParameters: args,
        parameters: args.toString()
      };
      self.registerCall(call);

      const result = self.originalJQuery.apply(this, args) as JQuery<HTMLElement>;
      return self.wrapFunction(result);
    } as JQueryStatic;

    Object.assign(interceptor, this.originalJQuery);

    for (const prop in interceptor) {
      if (typeof interceptor[prop] === 'function') {
        interceptor[prop] = this.wrapMethod(prop, interceptor[prop]);
      }
    }

    window.jQuery = interceptor;
    window.$ = interceptor;
  }

  public trackUsedAPIs(handler: Handler) {
    this.registry.forEach(({ method, parameters }) => {
      const signal = new Signal({
        method,
        parameters
      });

      handler(signal);
    });
  }

  public reset() {
    window.$ = this.originalJQuery;
    window.jQuery = this.originalJQuery;
    this.registry = [];
  }
}

export default JQueryDetector.instance;
