import PropTypes from 'prop-types';
import React from 'react';

// Panels
import PanelCookieConsent from '@components/CookieBanner/V1/PanelCookieConsent';
import PanelLegalNotice from '@components/CookieBanner/V1/PanelLegalNotice';
import PanelMain from '@components/CookieBanner/V1/PanelMain';
import PanelTechnicallyNecessary from '@components/CookieBanner/V1/PanelTechnicallyNecessary';
// Shared
import CookieBanner from '@components/CookieBanner/shared/CookieBanner';
import { COOKIE_BANNER_VERSION_V1 } from '@components/CookieBanner/shared/utils';
import StackNavigator from '@components/StackNavigator';

import {
  PANEL_ID_COOKIE_CONSENT,
  PANEL_ID_LEGAL_NOTICE,
  PANEL_ID_MAIN,
  PANEL_ID_TECHNICALLY_NECESSARY
} from './utils';

function CookieBannerV1(props) {
  const { isOpen, onClose, fadeIn, isRTL } = props;

  return (
    <StackNavigator.Provider initialRouteName={PANEL_ID_MAIN}>
      {(navigation) => (
        <CookieBanner.Root
          isOpen={isOpen}
          fadeIn={fadeIn}
          onClose={onClose}
          version={COOKIE_BANNER_VERSION_V1}
          resetNavigation={() => navigation.push(PANEL_ID_MAIN)}
        >
          <CookieBanner.Header
            onBack={navigation.pop}
            onClose={onClose}
            showBackButton={navigation.pathname !== PANEL_ID_MAIN}
            version={COOKIE_BANNER_VERSION_V1}
            isRTL={isRTL}
          />
          <CookieBanner.Content>
            <StackNavigator.Consumer navigation={navigation}>
              <StackNavigator.View id={PANEL_ID_MAIN}>
                <PanelMain navigation={navigation} />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_TECHNICALLY_NECESSARY}>
                <PanelTechnicallyNecessary />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_COOKIE_CONSENT}>
                <PanelCookieConsent />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_LEGAL_NOTICE}>
                <PanelLegalNotice />
              </StackNavigator.View>
            </StackNavigator.Consumer>
          </CookieBanner.Content>
        </CookieBanner.Root>
      )}
    </StackNavigator.Provider>
  );
}

CookieBannerV1.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fadeIn: PropTypes.bool.isRequired,
  isRTL: PropTypes.bool.isRequired
};

export default CookieBannerV1;
