import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import components from '@redux/reducers/components';
import cookieBanner from '@redux/reducers/cookieBanner';
import surveyDebugger from '@redux/reducers/debugger';
import htmlInputs from '@redux/reducers/htmlInputs';
import mediaQuery from '@redux/reducers/mediaQueryWatcher';
import options from '@redux/reducers/options';
import pageSettings from '@redux/reducers/pageSettings';
import questions from '@redux/reducers/questions';
import surveyStatus from '@redux/reducers/surveyStatus';

import { getStore as getSurveyData } from '@services/surveyDataProvider';

const services = { getSurveyData };
const enhancedThunk = [thunk.withExtraArgument(services)];

export default function createStoreWithInitialState(initialState) {
  return configureStore({
    reducer: {
      pageSettings,
      htmlInputs,
      options,
      questions,
      mediaQuery,
      surveyStatus,
      components,
      cookieBanner,
      navigation: (state = {}) => state,
      conditions: (state = {}) => state,
      fields: (state = {}) => state,
      fieldControlledBy: (state = {}) => state,
      surveyDebugger
    },
    preloadedState: initialState,
    middleware: enhancedThunk
  });
}

export type AppStore = ReturnType<typeof createStoreWithInitialState>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
