import PropTypes from 'prop-types';
import React from 'react';

import Accordion from '@commons/Accordion';
import Checkbox from '@commons/Checkbox';

import CookieCategoryControl from '@components/CookieBanner/V3/CookieCategoryControl';
import CookieCategoryDetails from '@components/CookieBanner/V3/CookieCategoryDetails';
import { COOKIE_CATEGORY, PANEL_ID_CATEGORY } from '@components/CookieBanner/V3/utils';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';
import { propTypes as StackNavigatorPropTypes } from '@components/StackNavigator/utils';
import stripHtml from '@utils/stripHtml';

const PanelCookieSettings = ({
  navigation,
  categories,
  allSelected,
  // Actions
  onSelectAll,
  onDeselectAll,
  onToggleCategory,
  onConfirmChoices,
  // Translations
  title,
  confirmText,
  cookieDetailsText,
  selectAllCookiesText,
  requiredText
}) => {
  const handleSelectAll = () => {
    if (allSelected) {
      onDeselectAll();
    } else {
      onSelectAll();
    }
  };

  return (
    <CookieBannerPanel.Root>
      <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>
      <CookieBannerPanel.Section isRow noMargin>
        <Checkbox id="checkbox-all-cookies" checked={allSelected} onChange={handleSelectAll}>
          <span dangerouslySetInnerHTML={{ __html: selectAllCookiesText }} />
        </Checkbox>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.Section>
        <Accordion.Root>
          {categories.map((category) => (
            <Accordion.Tab
              key={category.categoryType}
              id={category.categoryType}
              title={
                <CookieCategoryControl
                  id={category.categoryType}
                  title={category.title}
                  value={category.consent}
                  onChange={onToggleCategory}
                  disabled={category.categoryType === COOKIE_CATEGORY.TECHNICALLY_NECESSARY}
                  requiredLabel={stripHtml(requiredText)}
                />
              }
            >
              <CookieCategoryDetails
                category={PANEL_ID_CATEGORY[category.categoryType]}
                navigation={navigation}
                linkText={cookieDetailsText}
              >
                <span dangerouslySetInnerHTML={{ __html: `${category.message}` }} />
              </CookieCategoryDetails>
            </Accordion.Tab>
          ))}
        </Accordion.Root>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.ActionArea>
        <CookieBannerPanel.Action full onClick={onConfirmChoices}>
          <span dangerouslySetInnerHTML={{ __html: confirmText }} />
        </CookieBannerPanel.Action>
      </CookieBannerPanel.ActionArea>
    </CookieBannerPanel.Root>
  );
};

PanelCookieSettings.propTypes = {
  navigation: StackNavigatorPropTypes.navigation.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      category: PropTypes.string
    })
  ).isRequired,
  allSelected: PropTypes.bool.isRequired,
  // Actions
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onToggleCategory: PropTypes.func.isRequired,
  onConfirmChoices: PropTypes.func.isRequired,
  // Translations
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cookieDetailsText: PropTypes.string.isRequired,
  selectAllCookiesText: PropTypes.string.isRequired,
  requiredText: PropTypes.string.isRequired
};

export default PanelCookieSettings;
