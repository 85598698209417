import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import {
  ARIA_DESCRIPTION_ID,
  ARIA_TITLE_ID,
  cookieBannerVersionPropType,
  isV1,
  isV3,
  isbreakingVersion,
  versionClassNameAuthority
} from '@components/CookieBanner/shared/utils';

import layoutStyles from '@css/layout.scss';

import { isEnhancedCookieBannerEnabled } from '@services/featureFlags';

import styles from './cookieBannerContainer.scss';

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

export const KEY_TAB = 9;
export const KEY_ESCAPE = 27;

export const COOKIE_BANNER_ROOT_VARIANT = {
  primary: 'PRIMARY',
  secondary: 'SECONDARY'
};

const classNameMapping = {
  [COOKIE_BANNER_ROOT_VARIANT.primary]: styles.primary,
  [COOKIE_BANNER_ROOT_VARIANT.secondary]: styles.secondary
};

class CookieBannerContainer extends React.PureComponent {
  static propTypes = {
    version: cookieBannerVersionPropType.isRequired,
    variant: PropTypes.oneOf(Object.values(COOKIE_BANNER_ROOT_VARIANT)),
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fadeIn: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    resetNavigation: PropTypes.func.isRequired
  };

  static defaultProps = {
    variant: COOKIE_BANNER_ROOT_VARIANT.primary
  };

  constructor(props) {
    super(props);
    this.containerRef = null;
  }

  // eslint-disable-next-line react/sort-comp
  getFocusableElements = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const container = ReactDOM.findDOMNode(this.containerRef);
    if (!container) {
      return [];
    }
    const focusable = container.querySelectorAll(
      'a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    return [...focusable];
  };

  componentDidUpdate(prevProps) {
    const { version, isOpen, resetNavigation } = this.props;

    if (prevProps.isOpen === isOpen) {
      return;
    }

    if (isEnhancedCookieBannerEnabled()) {
      if (isV1(version) && isOpen) {
        this.containerRef.showModal();
      }
    }

    if (isV1(version) && !isOpen) {
      resetNavigation();
      return;
    }

    if (isV3(version) && isOpen) {
      this.containerRef.showModal();
    }

    if (isV3(version) && !isOpen) {
      resetNavigation();
      this.containerRef.close();
    }

    const focusable = this.getFocusableElements();

    if (!focusable.includes(document.activeElement) && focusable.length) {
      focusable[0].focus();
    }
  }

  componentDidMount() {
    if (!this.props.isOpen) {
      return;
    }

    if (isV3(this.props.version)) {
      this.containerRef.showModal();
    }

    if (isEnhancedCookieBannerEnabled() && isV1(this.props.version)) {
      this.containerRef.showModal();
    }

    const focusable = this.getFocusableElements();
    focusable[0].focus();
  }

  handleBackwardTab(e) {
    const focusable = this.getFocusableElements();
    const first = focusable[0];
    if (document.activeElement === first) {
      const last = focusable[focusable.length - 1];
      e.preventDefault();
      last.focus();
    }
  }

  handleForwardTab(e) {
    const focusable = this.getFocusableElements();
    const last = focusable[focusable.length - 1];
    if (document.activeElement === last) {
      const first = focusable[0];
      e.preventDefault();
      first.focus();
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === KEY_ESCAPE) {
      this.props.onClose();
    }

    if (e.keyCode !== KEY_TAB) {
      return;
    }

    if (e.shiftKey) {
      this.handleBackwardTab(e);
      return;
    }
    this.handleForwardTab(e);
  };

  getWrapperProps() {
    return {
      'aria-modal': true,
      'aria-labelledby': ARIA_TITLE_ID,
      'aria-describedby': ARIA_DESCRIPTION_ID,
      onKeyDown: this.handleKeyDown,
      ref: (el) => {
        this.containerRef = el;
      }
    };
  }

  renderV1(wrapperClasses, containerClasses) {
    if (isEnhancedCookieBannerEnabled()) {
      return (
        <dialog {...this.getWrapperProps()} className={wrapperClasses}>
          <div className={containerClasses}>{this.props.children}</div>
        </dialog>
      );
    } else {
      return (
        <div {...this.getWrapperProps()} className={wrapperClasses} role="dialog">
          <div className={containerClasses}>{this.props.children}</div>
        </div>
      );
    }
  }

  renderV3(wrapperClasses, containerClasses) {
    return (
      <dialog {...this.getWrapperProps()} className={wrapperClasses}>
        <div className={containerClasses}>{this.props.children}</div>
      </dialog>
    );
  }

  render() {
    const { isOpen, fadeIn, version, variant } = this.props;

    if (isV1(version) && !isOpen) {
      return null;
    }

    const wrapperClasses = classNames(
      styles.wrapper,
      versionClassNameAuthority(version, styles.v1, styles.v3),
      { [styles.isEnhancedCookieBannerEnabled]: isEnhancedCookieBannerEnabled() }
    );

    const containerClasses = classNames(
      styles.container,
      versionClassNameAuthority(version, styles.v1, styles.v3),
      classNameMapping[variant],
      {
        [layoutStyles.layoutContainer_fadeIn]: fadeIn,
        [styles.breakingVersion]: isbreakingVersion() && isV1(version)
      }
    );

    return isV1(version)
      ? this.renderV1(wrapperClasses, containerClasses)
      : this.renderV3(wrapperClasses, containerClasses);
  }
}

export default CookieBannerContainer;
