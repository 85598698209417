import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieDetails.scss';

const CookieDetails = ({ cookiesInfo, cookieInfoLabels }) => {
  const cookieAttributes = [
    {
      label: cookieInfoLabels.nameText,
      key: 'name'
    },
    {
      label: cookieInfoLabels.providerText,
      key: 'provider'
    },
    {
      label: cookieInfoLabels.purposeText,
      key: 'purpose'
    },
    {
      label: cookieInfoLabels.durationText,
      key: 'duration'
    },
    {
      label: cookieInfoLabels.typeText,
      key: 'type'
    }
  ];

  const getCookieInformation = (cookie) =>
    cookieAttributes.map(
      (info, index) =>
        // eslint-disable-next-line react/no-array-index-key
        !isEmpty(cookie[info.key]) && (
          <div className={styles.row} key={index}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: `${info.label}:` }} />
            <div className={styles.info} dangerouslySetInnerHTML={{ __html: cookie[info.key] }} />
          </div>
        )
    );

  return (
    <div className={styles.detailsWrapper}>
      {Object.values(cookiesInfo).map((cookie, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.details} key={index}>
          {getCookieInformation(cookie)}
        </div>
      ))}
    </div>
  );
};

CookieDetails.propTypes = {
  cookiesInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  cookieInfoLabels: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CookieDetails;
