import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Dropdown from '@commons/Dropdown';
import Question from '@commons/Question';

import layoutStyles from '@css/layout.scss';

import { isDropdownAccessibilityEnabled } from '@services/featureFlags';

import styles from './singleDropdown.scss';

const altShape = PropTypes.shape({
  id: PropTypes.string,
  text: PropTypes.string
});

export class SingleDropdown extends PureComponent {
  static propTypes = {
    formkey: PropTypes.string,
    caption: PropTypes.string,
    alt: PropTypes.arrayOf(altShape),
    validationEmpty: PropTypes.string,
    validationFailed: PropTypes.string,
    requiredField: PropTypes.string,
    isMultiValued: PropTypes.bool,
    isSubComponent: PropTypes.bool,
    id: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([altShape, PropTypes.arrayOf(altShape)])
  };

  static defaultProps = {
    formkey: '',
    caption: '',
    alt: [],
    validationEmpty: '',
    validationFailed: '',
    requiredField: '',
    selected: undefined,
    isSubComponent: false,
    isMultiValued: false
  };

  render() {
    const {
      formkey,
      caption,
      validationEmpty,
      validationFailed,
      requiredField,
      alt,
      selected,
      isSearchable,
      isMultiValued,
      isRTL,
      isSubComponent
    } = this.props;

    const elementId = `dropdown-${formkey}`;
    const elementIdObject = isDropdownAccessibilityEnabled() ? { elementId } : {};
    const { id: selectedId = '' } = selected || {};

    const mainContainerStyles = classNames({ [styles.subComponentMainContainer]: isSubComponent });
    const fieldsetStyles = classNames({ [styles.subComponentFieldset]: isSubComponent });
    const dropdownWrapperStyles = classNames({ [layoutStyles.answers]: !isSubComponent });
    const containerStyles = classNames(layoutStyles.answer, styles.dropdownWrapper, {
      [styles.subComponentDropdown]: isSubComponent
    });

    return (
      <div className={`questionBlock singleDropdownQuestion ${mainContainerStyles}`}>
        <fieldset className={fieldsetStyles}>
          {!isSubComponent && (
            <Question
              caption={caption}
              validationEmpty={validationEmpty}
              validationFailed={validationFailed}
              requiredField={requiredField}
            />
          )}
          <div className={dropdownWrapperStyles}>
            <Dropdown
              containerStyles={containerStyles}
              dropdownList={alt}
              isMultiValued={isMultiValued}
              selected={selected}
              onChange={this.props.onChange}
              title={caption}
              isSubComponent={isSubComponent}
              isSearchable={isSearchable}
              isRTL={isRTL}
              {...elementIdObject}
            />
            {isMultiValued ? (
              selected.map((item) => (
                <input key={item.id} type="hidden" id={formkey} name={formkey} value={item.id} />
              ))
            ) : (
              <input type="hidden" id={formkey} name={formkey} value={selectedId} />
            )}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default SingleDropdown;
// TODO: Consider tracking with RUM.
// export default withQuestionMeasurement(SingleDropdown, 'dropdown');
