import PropTypes from 'prop-types';
import React from 'react';

// Shared
import CookieBanner from '@components/CookieBanner/shared/CookieBanner';
import { COOKIE_BANNER_ROOT_VARIANT } from '@components/CookieBanner/shared/CookieBanner/Root';
import { COOKIE_BANNER_VERSION_V3 } from '@components/CookieBanner/shared/utils';
import StackNavigator from '@components/StackNavigator';

// Panels
import PanelCategoryDetails from './PanelCategoryDetails';
import PanelCookieConsent from './PanelCookieConsent';
import PanelCookieSettings from './PanelCookieSettings';
import PanelLegalNotice from './PanelLegalNotice';
import PanelMain from './PanelMain';
import {
  COOKIE_CATEGORY,
  PANEL_ID_CATEGORY,
  PANEL_ID_COOKIE_CONSENT,
  PANEL_ID_COOKIE_SETTINGS,
  PANEL_ID_LEGAL_NOTICE,
  PANEL_ID_MAIN,
  isCookieCategoryPanel
} from './utils';

function CookieBannerV3(props) {
  const { isOpen, onClose, fadeIn, backButtonText, isRTL } = props;

  return (
    <StackNavigator.Provider initialRouteName={PANEL_ID_MAIN}>
      {(navigation) => (
        <CookieBanner.Root
          isOpen={isOpen}
          fadeIn={fadeIn}
          onClose={onClose}
          version={COOKIE_BANNER_VERSION_V3}
          variant={
            isCookieCategoryPanel(navigation.pathname)
              ? COOKIE_BANNER_ROOT_VARIANT.secondary
              : COOKIE_BANNER_ROOT_VARIANT.primary
          }
          resetNavigation={() => navigation.push(PANEL_ID_MAIN)}
        >
          <CookieBanner.Header
            onBack={navigation.pop}
            onClose={onClose}
            showBackButton={navigation.pathname !== PANEL_ID_MAIN}
            version={COOKIE_BANNER_VERSION_V3}
            backButtonText={backButtonText}
            isRTL={isRTL}
          />
          <CookieBanner.Content>
            <StackNavigator.Consumer navigation={navigation}>
              <StackNavigator.View id={PANEL_ID_MAIN}>
                <PanelMain navigation={navigation} />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_COOKIE_CONSENT}>
                <PanelCookieConsent />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_LEGAL_NOTICE}>
                <PanelLegalNotice />
              </StackNavigator.View>
              {/* Cookie Settings */}
              <StackNavigator.View id={PANEL_ID_COOKIE_SETTINGS}>
                <PanelCookieSettings navigation={navigation} />
              </StackNavigator.View>
              {/* Cookie Categories */}
              <StackNavigator.View id={PANEL_ID_CATEGORY.TECHNICALLY_NECESSARY}>
                <PanelCategoryDetails category={COOKIE_CATEGORY.TECHNICALLY_NECESSARY} />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_CATEGORY.STATISTICS}>
                <PanelCategoryDetails category={COOKIE_CATEGORY.STATISTICS} />
              </StackNavigator.View>
              <StackNavigator.View id={PANEL_ID_CATEGORY.USER_EXPERIENCE}>
                <PanelCategoryDetails category={COOKIE_CATEGORY.USER_EXPERIENCE} />
              </StackNavigator.View>
            </StackNavigator.Consumer>
          </CookieBanner.Content>
        </CookieBanner.Root>
      )}
    </StackNavigator.Provider>
  );
}

CookieBannerV3.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fadeIn: PropTypes.bool.isRequired,
  backButtonText: PropTypes.string,
  isRTL: PropTypes.string
};

export default CookieBannerV3;
