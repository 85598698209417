export function convertToInt(value: string | number, defaultValue?: number): number {
  const int = parseInt(String(value), 10);

  if (Number.isNaN(int)) {
    console.log(`convertToInt(): invalid value ${value}, returning ${defaultValue || 0}!`);
    return defaultValue ?? 0;
  }

  return int;
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
