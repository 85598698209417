//----------------------------------------------------------------------
// CONSTANTS
//----------------------------------------------------------------------

export const APPLICATION_BUNDLE_VERSION =
  process.env.CITOOLS_CALCULATED_PACKAGE_VERSION ?? process.env.npm_package_version;

export function isSBX() {
  const urlParts = location.hostname.split('.');
  return urlParts.some((part) => part === 'sbx');
}

export function isMerlin() {
  if (window.surveyData && window.surveyData.specId) {
    const { specId } = window.surveyData;
    return specId.includes('merlin');
  }
  return false;
}

export function isLocalServer() {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
}

//----------------------------------------------------------------------
// SPEC
//----------------------------------------------------------------------
