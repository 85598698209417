import classNames from 'classnames';
import React from 'react';

import styles from '@assets/fonts/medallicons.scss';

//----------------------------------------------------------------------
// CONFIG
//----------------------------------------------------------------------

export const ICON_TYPES = {
  ARROW_DROPDOWN: '',
  ARROW_LEFT: '',
  ARROW_RIGHT: '',
  ARROW_UP: '',
  ARROW_UP_CHEVRON: '\u2303',
  CALENDAR: '',
  CHECKBOX: '',
  CLOSE: '',
  FILE_AUDIO: '',
  FILE_EXCEL: '',
  FILE_GENERIC: '',
  FILE_IMAGE: '',
  FILE_PDF: '',
  FILE_POWER_POINT: '',
  FILE_VIDEO: '',
  FILE_WORD: '',
  FILE_ZIP: '',
  INFORMATION: '',
  POWERED_BY_MEDALLIA: '',
  SEARCH: (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
      <path
        fill="#7A8394"
        fillRule="evenodd"
        d="M10.681 9.969a6.058 6.058 0 1 0-.713.713L15.287 16l.713-.713-5.319-5.318Zm-4.618 1.135a5.042 5.042 0 1 1 5.041-5.041 5.048 5.048 0 0 1-5.04 5.04Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  STAR: '',
  TRASH_BIN: '',
  WARNING: (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24">
      <g fill="none">
        <path
          d="m12 0c-6.627417 0-12 5.372583-12 12s5.372583 12 12 12 12-5.372583 12-12c0-3.18259788-1.2642821-6.23484483-3.5147186-8.48528137-2.2504366-2.25043655-5.3026835-3.51471863-8.4852814-3.51471863z"
          fill="#F4D224"
        />
        <g fill="#fff" transform="translate(10 5)">
          <path d="m1.144 8.32591873c-.02179954.24149215.13527367.47208145.40725702.59786846.27198335.125787.61350261.125787.88548596 0 .27198335-.12578701.42905656-.35637631.40725702-.59786846l.176-7.51941397c0-.43065583-.45913199-.77977136-1.0255-.77977136s-1.0255.34911553-1.0255.77977136z" />
          <path d="m2.005 10.9775c-.40442597-.0180912-.79783025.1346531-1.08408857.4209114-.28625832.2862584-.43900263.6796626-.42091143 1.0840886-.01437777.4013293.13844419.790627.422 1.075.28832953.2821742.67983528.4336432 1.083.419.40150718.0177698.79202284-.1338844 1.07630427-.4179757.28428142-.2840912.43619687-.6745053.41869573-1.0760243.01553145-.4038217-.13519093-.7963505-.417-1.086-.28593678-.2827199-.67618769-.4344037-1.078-.419z" />
        </g>
      </g>
    </svg>
  )
};

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

interface Props {
  type: JSX.Element | string;
  containerStyles?: string;
  shouldRender?: boolean;
  ariaHidden?: boolean;
  ariaLabel?: string;
}

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

export default function Icon(props: Props) {
  if (!props.shouldRender) return null;
  const { type, ariaHidden, containerStyles, ariaLabel } = props;
  const iconStyles = classNames(styles.medallicons, containerStyles);

  if (ariaHidden && ariaLabel) {
    return (
      <span>
        <span className={styles.medalliconLabel}>{ariaLabel}</span>
        <i aria-hidden={ariaHidden} className={iconStyles}>
          {type}
        </i>
      </span>
    );
  }

  return (
    <i aria-hidden={ariaHidden} className={iconStyles}>
      {type}
    </i>
  );
}

Icon.defaultProps = {
  ariaHidden: true,
  shouldRender: true
};
