/* eslint-disable camelcase */
import loadable from '@loadable/component';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import CookieBanner from '@components/CookieBanner';
import GeoLocation from '@components/GeoLocation';
import NavigationButtons from '@components/NavigationButtons';
import TimeoutWarning from '@components/TimeoutWarning';

import styles from '@css/layout.scss';

import { initDebugger } from '@redux/reducers/debugger';
import { AppDispatch, RootState } from '@redux/store';

import {
  isA11yWfcEnhancementEnabled,
  isSurveysRuntimeDebuggerEnabled
} from '@services/featureFlags';

import runValidations from '@utils/runValidations';

import CustomCss from './CustomCss';
import PageBody from './PageBody';
import PageField from './PageField';
import PageFooter from './PageFooter';
import PageHead from './PageHead';

const Debugger = loadable(() => import('@components/debugger'));

const { arrayOf, bool, number, object, shape, string, func } = PropTypes;

interface PageTemplateProps extends ConnectorProps {}

export class PageTemplate extends Component<PageTemplateProps> {
  static propTypes = {
    action: string,
    setAceFields: bool,
    textDirection: string,

    timeoutEnabled: bool,
    surveyTimeoutSeconds: number,
    surveyTimeoutWarningSeconds: number,

    desktop_params: shape({
      customfootereverypage: bool,
      Decorative_logo_alt: string,
      Decorative_logo: string,
      disableTechSupport: bool,
      html_feed_ug_customfooter: string,
      Page_logo_alt: string,
      Page_logo: string,
      Splash_logo_alt: string,
      Splash_logo: string,
      techSupportLink: string,
      clientName: string
    }),
    languages: arrayOf(
      shape({
        active: bool,
        key: string,
        name: string
      })
    ),
    isFirstPage: bool,
    pagerPage: number,
    pagerPercent: number,
    surveyLanguage: string,
    title: string,

    body: object,
    customMessageHandling: bool,
    defPgrAction: string,
    pagerValidationFailed: bool,
    storeUserLocation: bool,

    questionBlocks: arrayOf(object),
    translations: object,

    currentYear: string,
    surveyid: string,
    specId: string,
    clientId: string,
    isMobile: bool,
    native_lang_enabled: bool
  };

  static defaultProps = {
    currentYear: `${new Date().getFullYear()}`,
    desktop_params: {},
    pagerPercent: 0,
    pagerPage: -1,
    translations: {},
    native_lang_enabled: false
  };

  static childContextTypes = {
    translations: object,
    onSubmit: func
  };

  constructor(props) {
    super(props);
    this.isFirstPage = props.isFirstPage;
    this.autoSaveEnabled = props.autoSaveEnabled;
    this.hasFileUploadComponent = !!props.questionBlocks.find(
      ({ blockType }) => blockType === 'mediaStreamerFileUploader'
    );
  }

  sendAutoSaveInfo() {
    var form = document.querySelector('[name=surveyform]');
    var formData = new FormData(form);
    var bodyForm = new URLSearchParams(formData);
    bodyForm.append('autoSave', true);

    try {
      fetch(surveyData.action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        },
        body: bodyForm
      })
        .then((response) => response.json())
        .then((data) => {
          document.querySelector('[name="ballotVer"]').value = data.body.ballotVer;
        });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    if (this.autoSaveEnabled && this.isFirstPage) {
      setTimeout(() => {
        this.sendAutoSaveInfo();
      }, 1000);
    }

    if (isSurveysRuntimeDebuggerEnabled()) {
      await this.props.dispatch(initDebugger());
    }

    if (!this.hasFileUploadComponent) return;
    document.addEventListener('dragover', this._preventDragDefault);
  }

  componentWillUnmount() {
    if (!this.hasFileUploadComponent) return;
    document.removeEventListener('dragover', this._preventDragDefault);
  }

  _preventDragDefault(e) {
    e.preventDefault();
  }

  getChildContext() {
    return { translations: this.props.translations, onSubmit: runValidations };
  }

  render() {
    const {
      action,
      setAceFields,
      textDirection,

      // TimeoutWarning
      surveyTimeoutSeconds,
      surveyTimeoutWarningSeconds,
      timeoutEnabled,

      // PageHead
      desktop_params,
      languages,
      pagerPage,
      isFirstPage,
      pagerPercent,
      surveyLanguage,
      title,

      // PageField
      body,
      customMessageHandling,
      defPgrAction,
      pagerValidationFailed,
      storeUserLocation,

      // PageBody
      questionBlocks,
      translations,

      // PageFooter
      currentYear,
      isMobile,
      native_lang_enabled,

      // Debugger
      debuggerShouldRender
    } = this.props;

    const pageGlobalCssHook = pagerPage === -1 ? 'page-thankyou' : `page-${pagerPage}`;
    const containerStyles = classNames(styles[textDirection], textDirection, pageGlobalCssHook);

    // wrap page with form tag if action is not null
    const Container = action ? 'form' : 'div';
    const containerProps = action ? { name: 'surveyform', action, method: 'post' } : {};

    return (
      <div className={containerStyles}>
        <CustomCss />

        <Container {...containerProps}>
          <CookieBanner />

          <div>
            {setAceFields && <div id="acediv" />}
            <TimeoutWarning
              shouldRender={timeoutEnabled}
              surveyTimeoutSeconds={surveyTimeoutSeconds}
              surveyTimeoutWarningSeconds={surveyTimeoutWarningSeconds}
            />

            <input
              type="hidden"
              name="is_embedded"
              id="is_embedded"
              value={window.top !== window.self}
            />

            <PageHead
              pagerPage={pagerPage}
              isFirstPage={isFirstPage}
              decorativeLogo={desktop_params.Decorative_logo}
              decorativeLogoAlt={desktop_params.Decorative_logo_alt}
              splashLogo={desktop_params.Splash_logo}
              splashLogoAlt={desktop_params.Splash_logo_alt}
              pageLogo={desktop_params.Page_logo}
              pageLogoAlt={desktop_params.Page_logo_alt}
              pagerPercent={pagerPercent}
              languages={languages}
              surveyLanguage={surveyLanguage}
              surveyTitle={title}
              useNativeDropdown={native_lang_enabled}
            />

            <PageField
              ballotVersion={body.ballotVer}
              ballotVersionNotFound={body.ballotVerNotFound}
              showBackButtonDisabledMessage={body.showBackButtonDisabledMessage}
              pageIdxBiggerThanLastShown={body.pageIdxBiggerThanLastShown}
              customMessageHandling={customMessageHandling}
              defPgrAction={defPgrAction}
              fieldval={body.fieldval}
              fieldname={body.fieldname}
              pageNumber={pagerPage}
              nodeId={body.nodeId}
              pagerValidationFailed={pagerValidationFailed}
            />

            <PageBody questionBlocks={questionBlocks} isMobile={isMobile} {...translations} />

            {debuggerShouldRender && <Debugger surveyData={window.surveyData} />}

            {!isA11yWfcEnhancementEnabled() && <NavigationButtons />}

            {storeUserLocation && <GeoLocation />}
            <PageFooter
              showContent={isFirstPage || desktop_params.customfootereverypage}
              customFooter={desktop_params.html_feed_ug_customfooter}
              currentYear={currentYear}
              techSupportLink={desktop_params.techSupportLink}
              disableTechSupport={desktop_params.disableTechSupport}
            />
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ surveyDebugger }: RootState) => {
  return { debuggerShouldRender: surveyDebugger.isRuntimeDebuggerEnabled };
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(PageTemplate);
