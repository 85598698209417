/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import {
  PANEL_ID_LEGAL_NOTICE,
  PANEL_ID_TECHNICALLY_NECESSARY
} from '@components/CookieBanner/V1/utils';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';
import {
  COOKIE_BANNER_VERSION_V1,
  createNavigationLink
} from '@components/CookieBanner/shared/utils';
import { propTypes as StackNavigatorPropTypes } from '@components/StackNavigator/utils';

import styles from './panelMain.scss';

const MainPanel = ({
  acceptButtonText,
  description,
  legalInfoLinkText,
  onAccept,
  technicallyNecessaryLinkText,
  title,
  navigation
}) => {
  return (
    <CookieBannerPanel.Root>
      <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>

      <CookieBannerPanel.Description parseHTML>{description}</CookieBannerPanel.Description>

      <CookieBannerPanel.Section>
        <CookieBannerPanel.Link
          version={COOKIE_BANNER_VERSION_V1}
          onClick={createNavigationLink(navigation, PANEL_ID_LEGAL_NOTICE)}
        >
          {legalInfoLinkText}
        </CookieBannerPanel.Link>
      </CookieBannerPanel.Section>

      <a
        href="#"
        className={styles.categoryItem}
        onClick={createNavigationLink(navigation, PANEL_ID_TECHNICALLY_NECESSARY)}
      >
        <span dangerouslySetInnerHTML={{ __html: technicallyNecessaryLinkText }} />
        <Icon type={ICON_TYPES.ARROW_RIGHT} />
      </a>

      <CookieBannerPanel.ActionArea>
        <CookieBannerPanel.Action name="cookieBannerConfirmation" onClick={onAccept} full>
          <span dangerouslySetInnerHTML={{ __html: acceptButtonText }} />
        </CookieBannerPanel.Action>
      </CookieBannerPanel.ActionArea>
    </CookieBannerPanel.Root>
  );
};

MainPanel.propTypes = {
  acceptButtonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  legalInfoLinkText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  technicallyNecessaryLinkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  navigation: StackNavigatorPropTypes.navigation.isRequired
};

export default MainPanel;
