import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import set from 'lodash/set';
import React, {
  ChangeEvent,
  Component,
  DetailedHTMLProps,
  HTMLAttributes,
  TextareaHTMLAttributes
} from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';
import Question from '@commons/Question';

import QualityMeter, { QualityMeterText } from '@components/QualityMeter';

import layoutStyles from '@css/layout.scss';

import {
  isAdvancedAccessibilityEnabled,
  isTextAreaAccessibilityEnabled,
  isTextareaPlaceholderEnabled,
  isWCAG21Enabled
} from '@services/featureFlags';
import { getTranslation } from '@services/translations';

import stripHtml from '@utils/stripHtml';

import styles from './textarea.scss';

const DEFAULT_TEXTAREA_CHARACTER_LIMIT_REACHED = 'Character limit reached.';

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

interface Props {
  formkey: string;
  caption: string;
  value: string;
  maxChars: number;
  validationEmpty: string;
  validationFailed: string;
  requiredField: string;
  placeHolderText: string;
  id: number;
  setComponentAnswer: Function;
  isInMatrix: boolean;
  validationMessages: Array<string>;
  hasQualityMeter: boolean;
  qualityMeterDismissCount: number;
  qualityMeterTexts: Array<QualityMeterText>;
}

interface State {
  currentCount: number;
  showQualityMeter: boolean;
  textareaIsFocused: boolean | undefined;
  value: Props['value'];
  ariaBusyOnChange: Record<string, string> | null;
  ariaLiveRegionRelationship: string;
}

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

class TextArea extends Component<Props, State> {
  static defaultProps = {
    formkey: '',
    caption: '',
    value: null,
    disableAutocomplete: false,
    hasQualityMeter: false,
    qualityMeterTexts: [],
    validationMessages: [],
    validationEmpty: '',
    validationFailed: '',
    requiredField: '',
    placeHolderText: null,
    id: -1,
    setComponentAnswer: () => {},
    isInMatrix: false
  };

  static calculateHeight(element: ChangeEvent<HTMLTextAreaElement>['target']) {
    set(element, 'style.height', 'auto');
    const { scrollHeight } = element;
    set(element, 'style.height', `${scrollHeight}px`);
  }

  debouncedSetAnswer: typeof this.setAnswer;
  qualityMeterShowCount: number;
  qualityMeterDismissCount: number;
  // @ts-ignore
  _$textarea: HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);

    // 1st count is used for qualityMeterShowCount
    this.qualityMeterShowCount = get(props.qualityMeterTexts, '[0].count', 0);
    this.qualityMeterDismissCount = props.qualityMeterDismissCount
      ? props.qualityMeterDismissCount
      : Infinity;

    const currentCount = (props.value && props.value.length) || 0;
    const showQualityMeter = this.shouldShowQualityMeter(currentCount);

    this.state = {
      currentCount,
      showQualityMeter,
      value: props.value || '',
      textareaIsFocused: false,
      ariaLiveRegionRelationship: 'aria-describedby',
      ariaBusyOnChange: null
    };

    this.debouncedSetAnswer = debounce(this.setAnswer, 300);
  }

  setAnswer = (value: string) => {
    this.props.setComponentAnswer(value);
  };

  handleOnTextareaChange: TextareaHTMLAttributes<HTMLTextAreaElement>['onChange'] = (e) => {
    const currentText = e.target.value;
    // recalculate the number of characters typed in so far
    this.updateCharacterCount(currentText);
    const characterCount = currentText.length;
    const { maxChars, isInMatrix } = this.props;
    let ariaBusyOnChange = null;

    if (maxChars) {
      const assertiveFlag = maxChars * 0.9;

      if (characterCount >= assertiveFlag) {
        ariaBusyOnChange = { 'aria-busy': 'false' };
      } else {
        if (characterCount % 50 === 0) {
          ariaBusyOnChange = { 'aria-busy': 'false' };
        } else {
          ariaBusyOnChange = { 'aria-busy': 'true' };
        }
      }
    }

    if (isInMatrix) {
      TextArea.calculateHeight(e.target);
    }

    this.setState({
      value: currentText,
      ariaBusyOnChange,
      ariaLiveRegionRelationship: 'aria-controls'
    });

    this.debouncedSetAnswer(currentText);
  };

  handleOnTextareaBlur = () => {
    this.setState({ textareaIsFocused: false, ariaLiveRegionRelationship: 'aria-describedby' });
  };

  handleOnTextareaFocus = () => {
    this.setState({ textareaIsFocused: true });
  };

  handleOnShortenedTextareaFocus = () => {
    this.setState({ textareaIsFocused: true }, () => {
      if (this._$textarea) {
        this._$textarea.focus();
        TextArea.calculateHeight(this._$textarea);
      }
    });
  };

  shouldShowQualityMeter(charCount: number) {
    return charCount < this.qualityMeterDismissCount;
  }

  updateCharacterCount = (newText: string) => {
    const currentCount = (newText && newText.length) || 0;
    const showQualityMeter = this.shouldShowQualityMeter(currentCount);
    this.setState({ currentCount, showQualityMeter });
  };

  getAriaDescribedBy(charCountId: string, qualityMeterId: string, charLimitId: string) {
    const { showQualityMeter } = this.state;
    const { maxChars, hasQualityMeter } = this.props;
    const ariaDescriptors = [];

    if (hasQualityMeter && showQualityMeter) {
      ariaDescriptors.push(qualityMeterId);
    }

    if (maxChars) {
      ariaDescriptors.push(charCountId);
    }

    if (isTextAreaAccessibilityEnabled()) {
      maxChars && this.state.currentCount == maxChars;
      ariaDescriptors.push(charLimitId);
    }

    if (!ariaDescriptors.length) {
      return {};
    }

    const ariaDescribedByText = ariaDescriptors.join(' ');
    return { [this.state.ariaLiveRegionRelationship]: ariaDescribedByText };
  }

  setRefToTextarea = (domTextarea: HTMLTextAreaElement) => {
    this._$textarea = domTextarea;
  };

  render() {
    const {
      id,
      formkey,
      caption,
      maxChars,
      hasQualityMeter,
      qualityMeterTexts,
      validationEmpty,
      validationFailed,
      requiredField,
      placeHolderText,
      isInMatrix,
      validationMessages
    } = this.props;

    const { textareaIsFocused, currentCount, showQualityMeter, ariaBusyOnChange } = this.state;

    let ariaLive, ariaAtomic, ariaBusy;

    ariaLive = { 'aria-live': 'assertive' } as const;
    ariaAtomic = { 'aria-atomic': true };

    if (!ariaBusyOnChange) {
      ariaBusy = { 'aria-busy': 'true' };
    } else {
      ariaBusy = ariaBusyOnChange;
    }

    const charCountId = `${formkey}_CharCount`;
    const qualityMeterId = `${formkey}_QualityMeter`;
    const charLimitId = `${formkey}_CharLimit`;

    const ariaLabelledBy = id >= 0 && !isWCAG21Enabled() ? { 'aria-labelledby': `${id}` } : {};
    const ariaDescribedBy = this.getAriaDescribedBy(charCountId, qualityMeterId, charLimitId);
    const ariaLabel = { 'aria-label': `${stripHtml(caption)}` };

    const textareaContainerStyles = classNames(styles.textareaContainer, layoutStyles.answer, {
      // @ts-ignore
      [styles.textareaContainer_isFocused]: textareaIsFocused
    });

    const textareaMessageContainerStyles = classNames(styles.textareaMessageContainer);
    // @ts-ignore
    const iconClassName = styles['icon_lineHeight'];
    const mainContainerStyles = classNames({ [layoutStyles.answers]: !isInMatrix || !!maxChars });
    const textareaStyles = classNames(styles.textarea, {
      [styles.textareaHidden]: isInMatrix && !textareaIsFocused
    });
    const shortenedTextareaStyles = classNames(styles.shortenedTextarea, styles.ellipsis, {
      [styles.textareaHidden]: textareaIsFocused
    });

    const ariaRequired = (requiredField || validationEmpty) && { 'aria-required': true };
    const ariaInvalid = (validationFailed || validationEmpty || validationMessages.length) && {
      'aria-invalid': true
    };
    const placeholder = isTextareaPlaceholderEnabled() &&
      placeHolderText && {
        placeholder: placeHolderText,
        'aria-placeholder': placeHolderText
      };
    const shortenedTextArea = (
      <div
        className={shortenedTextareaStyles}
        onFocus={this.handleOnShortenedTextareaFocus}
        suppressContentEditableWarning
        contentEditable
      >
        {this.state.value}
      </div>
    );
    const characterLimitText =
      getTranslation('survey.TEXTAREA_CHARACTER_LIMIT_REACHED') ||
      DEFAULT_TEXTAREA_CHARACTER_LIMIT_REACHED;

    const component = [
      !isInMatrix && (
        <Question
          key="question"
          caption={caption}
          validationEmpty={validationEmpty}
          validationFailed={validationFailed}
          requiredField={requiredField}
          captionId={String(id)}
          hasLegendCaption={false}
          ariaId={formkey}
          validationMessages={validationMessages}
        />
      ),
      <div className={mainContainerStyles} key="textarea">
        <div className={textareaContainerStyles}>
          <div className={styles.textareaContainerOverflowHidden}>
            {isInMatrix && shortenedTextArea}
            <textarea
              className={textareaStyles}
              name={formkey}
              id={formkey}
              maxLength={maxChars}
              value={this.state.value}
              onChange={this.handleOnTextareaChange}
              onFocus={this.handleOnTextareaFocus}
              onBlur={this.handleOnTextareaBlur}
              ref={this.setRefToTextarea}
              {...placeholder}
              {...ariaLabelledBy}
              {...ariaDescribedBy}
              {...ariaRequired}
              {...ariaInvalid}
              {...ariaLabel}
            />

            {hasQualityMeter && (
              <QualityMeter
                id={qualityMeterId}
                qualityMeterTexts={qualityMeterTexts}
                characterCount={currentCount}
                showQualityMeter={showQualityMeter}
              />
            )}
          </div>
        </div>

        {isTextAreaAccessibilityEnabled() ? (
          <div className={textareaMessageContainerStyles}>
            {maxChars && this.state.currentCount >= maxChars && (
              <div className={styles.textareaWarningBlock}>
                <Icon containerStyles={iconClassName} type={ICON_TYPES.WARNING} />
                <span id={charLimitId} role="alert" className={styles.textareaCharacterLimit}>
                  {characterLimitText}
                </span>
              </div>
            )}

            {maxChars && (
              <div id={charCountId} className={styles.textareaCount}>
                {`${this.state.currentCount} / ${maxChars}`}
                <span className={styles.textareaCharacterTyped}> Characters Typed</span>
              </div>
            )}
          </div>
        ) : (
          <div className={textareaMessageContainerStyles}>
            {maxChars && (
              <div
                id={charCountId}
                className={styles.textareaCount}
                {...ariaLive}
                {...ariaAtomic}
                {...ariaBusy}
              >
                {`${this.state.currentCount} / ${maxChars}`}
                <span className={styles.textareaCharacterTyped}> Characters Typed</span>
              </div>
            )}
          </div>
        )}
      </div>
    ];

    if (isAdvancedAccessibilityEnabled()) {
      return <div className="questionBlock textareaQuestion">{component}</div>;
    }

    return (
      <div className="questionBlock textareaQuestion">
        <fieldset>{component}</fieldset>
      </div>
    );
  }
}

export default TextArea;
