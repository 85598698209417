import React, { FunctionComponent, PureComponent } from 'react';
import { Provider } from 'react-redux';
import { SurveyData } from 'types/SurveyData';

import PageTemplate from '@components/PageStructure/PageTemplate';

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

interface AppProps {
  questionBlocks: Array<any>;
  store: {};
  surveyData: SurveyData;
}

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

const App: FunctionComponent<AppProps> = (
  props = {
    questionBlocks: [],
    store: {},
    surveyData: {} as any
  }
) => {
  const { questionBlocks, store, surveyData } = props;
  return (
    // @ts-ignore
    <Provider store={store}>
      <PageTemplate questionBlocks={questionBlocks} {...surveyData} />
    </Provider>
  );
};

export default App;
