import { isEmpty } from 'lodash';

import { isCookieBannerEnabled, isFaroRUMEnabled } from '@services/featureFlags';

export function isTelemetryServiceEnabled() {
  return (
    !isEmpty(window.surveyData) &&
    !isEmpty(window.surveyData.rumconfigblock) &&
    !isCookieBannerEnabled() &&
    isFaroRUMEnabled()
  );
}
