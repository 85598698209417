import { isLocalServer, isMerlin, isSBX } from '@utils/meta';

import surveyDataOverrides from '../../../overrides.json';

//  ----------------------------------------------------------------------
//  CORE
//  ----------------------------------------------------------------------

export function shouldEnableSurveyDataOverrides() {
  const conditions = [isLocalServer(), isMerlin(), isSBX()];
  return conditions.some((condition) => condition === true);
}

export { surveyDataOverrides };
