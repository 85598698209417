import { get, isEmpty, isNil, noop, uniqBy } from 'lodash';
import { SurveyData } from 'types/SurveyData';

import { ClientSideRenderingFeatureFlag } from '@services/featureFlags';

import { formatBytes } from '@utils/math';

import { SURVEY_DEBUGGER_STORAGE_KEY } from './utils';

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

enum DebuggerServiceError {
  MISSING_STORE = 'DebuggerService / init() should only be called after setStore()',
  VPN_FAILED_FETCH = 'DebuggerService / fetchVpnStatus() failed',
  SPEC_FAILED_FETCH = 'DebuggerService / fetchSurveySpec() failed',
  STYLESHEET_FAILED_FETCH = 'DebuggerService / fetchBrandingStyleSheets() failed'
}

type Stylesheet = { name: string; body: string };
type DebuggerServiceInstance = typeof DebuggerService.instance;
type OverridesRegistry = Record<string, Partial<SurveyData>>;

export type FeatureFlagRegistry = Record<ClientSideRenderingFeatureFlag, boolean>;
export type SurveySpec = ReturnType<DebuggerServiceInstance['parseSurveySpec']>;
export type RawSurveySpec = Awaited<ReturnType<DebuggerServiceInstance['fetchSurveySpec']>>;
export type BrandingStyleSheets = Awaited<
  ReturnType<DebuggerServiceInstance['fetchBrandingStyleSheets']>
>;

//----------------------------------------------------------------------
// CORE
//----------------------------------------------------------------------

export class DebuggerService {
  static #instance: DebuggerService;

  private entryId: string = '';
  private surveyURL = window.location.href;
  private specURL = `${this.surveyURL.replace('&debug=runtime', '')}&debug=spec`;
  private ballotURL = `${this.surveyURL.replace('&debug=runtime', '')}&debug=ballot`;

  //----------------------------------------------------------------------
  // INSTANCE
  //----------------------------------------------------------------------

  private constructor() {
    if (!isNil(window.surveyData)) {
      this.entryId = window.surveyData.specId || window.surveyData.surveyid;
    }
  }

  public static get instance(): DebuggerService {
    if (!DebuggerService.#instance) {
      DebuggerService.#instance = new DebuggerService();
    }

    return DebuggerService.#instance;
  }

  //----------------------------------------------------------------------
  // PARSERS
  //----------------------------------------------------------------------

  private parseClusterURL(document: Document, specNode: Element) {
    const clusterUrl = specNode.getAttribute('cluster-url');

    if (!isNil(clusterUrl)) {
      return clusterUrl;
    }

    // Some instances might not have `cluster-url`.
    // We should fallback to `return-feed` and process this string.
    const returnFeed = document.getElementsByTagName('return-feed')[0];

    if (!isNil(returnFeed)) {
      const baseUrl = returnFeed.getAttribute('url')!;
      const url = new URL(baseUrl);
      const hostname = url.hostname.replace(/-be(?=\.[^/]+$)/, '');
      return `https://${hostname}`;
    }

    return null;
  }

  public parseSurveySpec(document: Document, size: number) {
    const specNode = document.getElementsByTagName('spec')[0];
    const surveyNode = document.getElementsByTagName('survey')[0];

    // # of Fields
    const surveyNodeChildren = Array.from(surveyNode.childNodes);
    const fields = surveyNodeChildren.filter((node) => node.nodeName === 'value');

    // # of Alt Sets
    const specNodeChildren = Array.from(specNode.childNodes);
    const altsets = specNodeChildren.filter((node) => node.nodeName === 'altset');

    const surveyProgramId = specNode.getAttribute('surveyProgramId');
    const companyName = specNode.getAttribute('company-name');
    const companyURL = this.parseClusterURL(document, specNode);

    const specSize = formatBytes(size);

    const payload = {
      surveyProgramId,
      companyName,
      companyURL,
      countFields: fields.length,
      countAltsets: altsets.length,
      specSize
    };

    return payload;
  }

  //----------------------------------------------------------------------
  // FETCHERS
  //----------------------------------------------------------------------

  public async fetchVpnStatus() {
    try {
      const resBody = await fetch(this.ballotURL);
      const resBodyType = resBody.headers.get('content-type') || '';
      const isXML = resBodyType.includes('application/xml');

      if (isXML) {
        return true;
      }

      return false;
    } catch (err) {
      console.error(DebuggerServiceError.VPN_FAILED_FETCH, err);
      return false;
    }
  }

  public async fetchSurveySpec() {
    try {
      const resBody = await fetch(this.specURL);
      const resBodyType = resBody.headers.get('content-type') || '';
      const isXML = resBodyType.includes('application/xml');

      if (isXML) {
        const rawContent = await resBody.text();
        const content = new window.DOMParser().parseFromString(rawContent, 'text/xml');

        const textEncoder = new TextEncoder();
        const size = textEncoder.encode(rawContent).length;

        return { content, size };
      }

      return null;
    } catch (err) {
      console.error(DebuggerServiceError.SPEC_FAILED_FETCH, err);
      return null;
    }
  }

  public async fetchBrandingStyleSheets() {
    const styleSheets = Array.from(document.styleSheets);
    const uniqStyleSheets = uniqBy(styleSheets, 'href');

    const filteredStyleSheets = uniqStyleSheets.filter(
      ({ href }) => !isNil(href) && !href.includes('main.css') && !href.includes('.chunk.css')
    );

    const styleSheetsFetchers = filteredStyleSheets.map(async (stylesheet) => {
      try {
        const response = await fetch(stylesheet.href!);

        if (!response.ok) {
          throw new Error(DebuggerServiceError.STYLESHEET_FAILED_FETCH);
        }

        const body = await response.text();
        const name = stylesheet.href!.split('/').pop();

        return { name, body };
      } catch (err) {
        console.error(DebuggerServiceError.STYLESHEET_FAILED_FETCH, err);
        return null;
      }
    });

    const contents = await Promise.all(styleSheetsFetchers);
    return contents.filter((entry): entry is Stylesheet => !isNil(entry) && !isNil(entry.name));
  }

  //----------------------------------------------------------------------
  // GETTERS
  //----------------------------------------------------------------------

  public hasDebuggerParam() {
    const urlParams = new URL(window.location.href).searchParams;
    const debugUrlParam = urlParams.get('debug');
    const hasRuntimeDebuggerParam = debugUrlParam === 'runtime';
    return hasRuntimeDebuggerParam;
  }

  public hasOverridesForAnySurvey() {
    const registry = localStorage.getItem(SURVEY_DEBUGGER_STORAGE_KEY);

    if (!isNil(registry)) {
      const currentValue = JSON.parse(registry) as OverridesRegistry;
      return !isEmpty(currentValue);
    }

    return false;
  }

  public getOverrides() {
    const registry = localStorage.getItem(SURVEY_DEBUGGER_STORAGE_KEY);

    if (isNil(registry)) {
      return {};
    }

    const parsedRecord = JSON.parse(registry) as OverridesRegistry;

    if (!isNil(parsedRecord) && !isEmpty(parsedRecord[this.entryId])) {
      return parsedRecord[this.entryId];
    }

    return {};
  }

  //----------------------------------------------------------------------
  // SETTERS
  //----------------------------------------------------------------------

  public setOverride(payload: Partial<SurveyData>) {
    const registry = localStorage.getItem(SURVEY_DEBUGGER_STORAGE_KEY);
    const newEntry = { [this.entryId]: payload };

    if (!isNil(registry)) {
      const existingOverrides = JSON.parse(registry) as OverridesRegistry;

      localStorage.setItem(
        SURVEY_DEBUGGER_STORAGE_KEY,
        JSON.stringify({ ...existingOverrides, ...newEntry })
      );
    } else {
      localStorage.setItem(SURVEY_DEBUGGER_STORAGE_KEY, JSON.stringify(newEntry));
    }

    window.location.reload();
  }

  public clearAllOverrides() {
    localStorage.setItem(SURVEY_DEBUGGER_STORAGE_KEY, JSON.stringify({}));
    window.location.reload();
  }
}

export default DebuggerService.instance;
