import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Cell from '@commons/Table/Cell';

import CellOption from '@components/RatingGrid/CellOption';
import rowOptionStyles from '@components/RatingGrid/RowOption/rowOption.scss';
import styles from '@components/RatingGrid/SelectionBar/selectionBar.scss';

import layoutStyles from '@css/layout.scss';

import { isAddingSelectedClassEnabled, isWCAG21_4_Enabled_FF } from '@services/featureFlags';

const SelectionCell = (props) => {
  const {
    optionId,
    showNumbers,
    showStars,
    optionDescribedBy,
    isNaOption,
    hasAnswer,
    isMultiValued,
    optionsSize,
    ariaValue
  } = props;

  const naOptionStyles = classNames(rowOptionStyles.naOption, layoutStyles.answer, {
    answer_isSelected: isAddingSelectedClassEnabled() && hasAnswer
  });

  const containerStyles = isNaOption
    ? rowOptionStyles.naSelectionContainer
    : classNames(styles.selectionUnit, layoutStyles.answer, {
        answer_isSelected: isAddingSelectedClassEnabled() && hasAnswer
      });

  const size = `${100 / optionsSize}%`;

  const liStyles = {
    boxSizing: 'border-box',
    borderTop: '1px solid #dae0ea',
    borderLeft: '1px solid #dae0ea',
    borderBottom: '1px solid #dae0ea',
    borderRight: '1px solid #dae0ea',
    display: 'inline-block',
    height: '61px',
    fontSize: '18px',
    minWidth: size
  };

  if (isWCAG21_4_Enabled_FF()) {
    return (
      <li
        role="none"
        containerStyles={containerStyles}
        aria-setsize={optionsSize}
        aria-posinset={ariaValue}
        style={{ ...liStyles }}
      >
        <CellOption
          id={optionId}
          showNumbers={showNumbers}
          showStars={showStars}
          ariaDescribedBy={optionDescribedBy}
          indicatorContainerStyles={isNaOption ? naOptionStyles : ''}
          isMultiValued={isMultiValued}
          optionsSize={optionsSize}
        />
      </li>
    );
  }

  return (
    <Cell containerStyles={containerStyles}>
      <CellOption
        id={optionId}
        showNumbers={showNumbers}
        showStars={showStars}
        ariaDescribedBy={optionDescribedBy}
        indicatorContainerStyles={isNaOption ? naOptionStyles : ''}
        isMultiValued={isMultiValued}
      />
    </Cell>
  );
};

const { bool, string, number } = PropTypes;

SelectionCell.propTypes = {
  optionId: string,
  showNumbers: bool,
  showStars: bool,
  optionDescribedBy: string,
  isNaOption: bool,
  hasAnswer: bool,
  isMultiValued: bool,
  optionsSize: number
};

SelectionCell.defaultProps = {
  optionId: '',
  showNumbers: false,
  showStars: false,
  optionDescribedBy: '',
  isNaOption: false,
  hasAnswer: false,
  isMultiValued: false,
  optionsSize: 0
};

export default SelectionCell;
