import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { getIOSVersion } from '@utils/browserAgent';

//----------------------------------------------------------------------
// CONSTANTS
//----------------------------------------------------------------------

export const COOKIE_BANNER_VERSION_V1 = 'V1';
export const COOKIE_BANNER_VERSION_V3 = 'V3';

export const ARIA_TITLE_ID = 'cookie-banner-title';
export const ARIA_DESCRIPTION_ID = 'cookie-banner-description';
export const ARIA_MESSAGE_ID = 'cookie-banner-message';

export const cookieBannerVersionPropType = PropTypes.oneOf([
  COOKIE_BANNER_VERSION_V1,
  COOKIE_BANNER_VERSION_V3
]);

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

export const isV1 = (version) => version === COOKIE_BANNER_VERSION_V1;
export const isV3 = (version) => version === COOKIE_BANNER_VERSION_V3;

export const versionClassNameAuthority = (version, classNameV1, classNameV3) =>
  isV1(version) ? classNameV1 : classNameV3;

export const createNavigationLink = (navigation, panel) => (evt) => {
  evt.preventDefault();
  navigation.push(panel);
};

export const isbreakingVersion = () => {
  const iOSVersion = getIOSVersion();

  if (iOSVersion) {
    if (
      (iOSVersion.iOSMajor === '16' || iOSVersion.iOSMajor === '15') &&
      iOSVersion.iOSMinor === '4'
    ) {
      return true;
    }
  }

  return false;
};

// See: https://jira.medallia.com/browse/OA-29061 and https://jira.medallia.com/browse/OA-29835
// As of 24CR4, Survey Engine can send `cookieNotice` being `undefined` with `cookieConfiguration`
// having a valid value. This will be reflected in the Redux store with `cookieBanner.deprecatedCookies`
// resulting in a length of `1` invalidating the previous
// `Object.keys(surveyData.cookieBanner).length > 0` condition.
export const hasCookieBannerEnabled = (surveyData) => {
  const baseConfig = omit(surveyData.cookieBanner, ['deprecatedCookies']);
  return Object.keys(baseConfig).length > 0;
};
