import { createSelector } from '@reduxjs/toolkit';
import { get, isEmpty, isNil, merge } from 'lodash';
import { useSelector } from 'react-redux';

import {
  clearAllOverrides,
  resetClientFeatureFlags,
  setOverride,
  surveyDebuggerSelector,
  updateClientFeatureFlag
} from '@redux/reducers/debugger';
import { RootState, useAppDispatch, useAppSelector } from '@redux/store';

import DebuggerService from '@services/debugger';
import {
  ClientSideRenderingFeatureFlag,
  normalizeSurveyEngineFeatureFlagsList
} from '@services/featureFlags';

//----------------------------------------------------------------------
// MISC
//----------------------------------------------------------------------

export const SURVEY_DEBUGGER_STORAGE_KEY = 'survey_debugger';

export function isSurveysRuntimeDebuggerEnabled() {
  return get(
    window,
    ['surveyData', 'featureFlags', '24cr4_MPC8530_surveys_runtime_debugger'],
    false
  );
}

export function bootstrapDebuggerOverrides() {
  const debuggerOverrides = localStorage.getItem(SURVEY_DEBUGGER_STORAGE_KEY);

  if (!isNil(debuggerOverrides) && isSurveysRuntimeDebuggerEnabled()) {
    const id = window.surveyData.specId || window.surveyData.surveyid;
    const newSurveyData = merge(window.surveyData, JSON.parse(debuggerOverrides)[id]);
    window.surveyData = newSurveyData;
  }
}

//----------------------------------------------------------------------
// SELECTORS
//----------------------------------------------------------------------

const hasOverridesSelector = (state: RootState) =>
  !isEmpty(state.surveyDebugger.clientFeatureFlags.draft);

const clientFeatureFlagsSelector = createSelector(
  [(state: RootState) => state.surveyDebugger.clientFeatureFlags.final],
  (clientFeatureFlags) => {
    return Object.entries(clientFeatureFlags).filter(
      (entry) => entry[0] !== '24cr4_MPC8530_surveys_runtime_debugger'
    ) as Array<[ClientSideRenderingFeatureFlag, boolean]>;
  }
);

//----------------------------------------------------------------------
// HOOKS
//----------------------------------------------------------------------

export function useDebugger() {
  const { isRuntimeDebuggerEnabled, brandingStyleSheets, surveySpec, loading, error } =
    useSelector(surveyDebuggerSelector);

  return { isRuntimeDebuggerEnabled, brandingStyleSheets, surveySpec, loading, error };
}

export function useFeatureFlagsDebugger() {
  const dispatch = useAppDispatch();
  const clientFeatureFlags = useAppSelector(clientFeatureFlagsSelector);
  const hasOverrides = useAppSelector(hasOverridesSelector);
  const state = useAppSelector(surveyDebuggerSelector);

  // State

  const hasOverridesForAnySurvey = DebuggerService.hasOverridesForAnySurvey();

  const hasOverrideFor = (id: ClientSideRenderingFeatureFlag) => {
    return !isNil(state.clientFeatureFlags.draft[id]);
  };

  const hasScheduledOverrideFor = (id: ClientSideRenderingFeatureFlag) => {
    if (hasOverrideFor(id)) {
      return state.clientFeatureFlags.draft[id] !== state.clientFeatureFlags.prev[id];
    }
  };

  // Events

  const onChange = (id: ClientSideRenderingFeatureFlag, newValue: boolean) => {
    dispatch(updateClientFeatureFlag({ id, value: newValue }));
  };

  const onResetSurveyOverrides = () => {
    dispatch(resetClientFeatureFlags());
  };

  const onResetAllOverrides = () => {
    dispatch(clearAllOverrides());
  };

  const onSave = () => {
    const newEntry = { featureFlags: state.clientFeatureFlags.draft };
    dispatch(setOverride(newEntry));
  };

  // API

  return {
    // Events
    onChange,
    onSave,
    onResetSurveyOverrides,
    onResetAllOverrides,
    // Predicates
    hasOverrides,
    hasOverrideFor,
    hasScheduledOverrideFor,
    hasOverridesForAnySurvey,
    // Data
    serverFeatureFlags: normalizeSurveyEngineFeatureFlagsList(),
    clientFeatureFlags
  };
}
